<template>
  <site-page padding>
    <div class="items-center search-page">
      <div class="text-h4 text-center q-mb-md">Search</div>
      <div class="search-form text-body2">
        <div class="row q-mb-sm items-center">
          <div class="col-auto sp-search-field q-mr-sm q-mb-sm">
            <q-input v-model="rawQueryString" @keyup.enter="doUserSearch" hide-bottom-space
              outlined stack-label clearable dense label="Search Terms" debounce="500"
              placeholder="aspen bear, 9860" @clear="clearSearchTerms">
            </q-input>
          </div>
          <div class="col-auto q-mr-sm q-mb-sm">
            <search-filters :selection="selectedFilters" @change="doFilterChange" />
          </div>
          <div class="col-auto q-mb-sm">
            <q-btn square unelevated size="md" class="search-button" color="primary" icon="search" @click="doUserSearch"/>
          </div>
        </div>
      </div>
      <div v-show="true">
      <q-table
      :rows="results"
      :columns="columns"
      row-key="itemId"
      :loading="loading"
      hide-header
      :rows-per-page-options="appState.itemsPerPageOptions"
      v-model:pagination="searchPagination"
      grid
      class="product-table"
      card-container-class="prod-grid-container justify-center"
      rows-per-page-label="Items per page"
      @request="onRequest"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="accent" />
      </template>
      <template v-slot:no-data>
        <div class="full-width row no-data text-accent q-gutter-sm q-mb-md" v-show="!loading">
          <div class="col">
            <div class="text-h5">No products were found matching your search terms.</div>
            <div class="text-subtitle1">Make sure your search is spelled correctly, or try a different search term.</div>
            <div v-if="bestsellers" class="text-subtitle1 q-mt-md">Here are some of our best sellers:
              <ItemGroupsComponent :group="bestsellers" :show-title="false" :thumbnails="true" />
            </div>
            <div class="text-body1">Still can't find what you want? <router-link to="/about/contact">Contact Us</router-link></div>
          </div>
        </div>
      </template>
      <template v-slot:top>
        <div id="match-count-desc" class="text-subtitle1" v-if="searchPagination.rowsNumber||0 > 0" v-html="matchDisplay()">
        </div>
        <q-space />
        <div v-if="isAdmin && (searchPagination.rowsNumber||0 > 0)">
          <q-btn label="Add All to Cart" @click="addAllToCart" />
        </div>
        <q-space />
        <div v-if="searchPagination.rowsNumber||0 > 0" class="nobr">
          <div class="row items-center justify-center">
            <div class="col-auto">Items per page: </div>
            <div class="col">
              <q-select
                v-model="itemsPerPage"
                :options="appState.itemsPerPageOptions"
                dense
                options-dense
                outlined
                class="q-ml-sm" />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item="props">
        <div class="product-box items-center q-ma-sm" :id="`product-${props.row.itemId}`">
          <router-link :to="{ name: 'ProductDetails', params: { itemId: props.row.itemId }}">
            <div class="q-mb-sm q-mx-md text-center">
              <q-img
                v-if="!props.row.hasImage"
                src="/images/product/noImage.png"
              />
              <product-image v-else :product="props.row" :format="formatForProduct(props.row)" :type="ImageType.Listing" />
            </div>
          </router-link>
          <div class="add-to-cart text-center wrap">
            <router-link :to="{ name: 'ProductDetails', params: { itemId: props.row.itemId }}">
              {{ props.row.name }} <span class="font-h6">&nbsp;{{ userStore.priceString(props.row) }}</span>
              <span v-if="props.row.customizable"><br><name-drop-badge /></span>
            </router-link>
          </div>
          <div class="add-to-cart text-center">
            <q-btn
              size="md"
              flat
              v-if="props.row.inStock"
              @click="addToCart(props.row)"
              >Add to Cart
            </q-btn>
            <strong v-else class="text-red">Out of stock</strong>
          </div>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="col" style="display: inline;">&nbsp;</div>
        <div class="col-auto text-center justify-center">
          <q-pagination
            v-model="searchPagination.page"
            :min="1"
            :max="lastPageNumber()"
            :max-pages="maxPages"
            :per-page="searchPagination.rowsPerPage"
            boundary-numbers
            boundary-links
            direction-links
            ellipses
          />
        </div>
        <div class="col">&nbsp;</div>
      </template>
    </q-table>
      </div>
    </div>
  </site-page>
</template>

<script setup lang="ts">
import { watch, ref, onBeforeMount, onMounted, computed } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useAppInfoStore } from 'src/stores/appState'
import { useAppServer } from 'src/boot/AppServer'
import { QTableProps, useMeta, useQuasar } from 'quasar'
import SearchFilters from 'src/components/SearchFilters.vue'

import { ItemFormat, Product } from 'app/na-core/src/models'
import { ImageType } from 'src/utils/ImageUtils'
import { QTableColumnsType } from 'app/na-core/src/quasar/QuasarTypes'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import ProductImage from 'src/components/ProductImage.vue'
import NameDropBadge from 'src/components/NameDropBadge.vue'
import { AddCartArgs, useCartStore } from 'src/stores/cart'
import { toAlliedError, useErrorService } from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import { useUserStore } from 'src/stores/userStore'
import { getInstance } from '@nowallied/quasar-app-extension-na-core/src/services/AuthCenter'
import { UserSearchParams } from 'src/models/UserSearch'
import { RouteLocation } from 'vue-router'
import ItemGroupsComponent from 'src/components/ItemGroupsComponent.vue'

// type hacks required to make paginiation work
type RequiredProperties<T, P extends keyof T> = Omit<T, P> & Required<Pick<T, P>>;
type QPagination = NonNullable<QTableProps['pagination']>
type QPaginationRequired = RequiredProperties<QPagination, 'page' | 'rowsPerPage' | 'rowsNumber'>

const $q = useQuasar()
const appState = useAppInfoStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const searchParams = ref<UserSearchParams>(new UserSearchParams())
const rawQueryString = ref('')
const searchPagination = ref<QPaginationRequired>({ page: 1, rowsPerPage: appState.itemsPerPage, rowsNumber: 0 })
const selectedFilters = ref<ItemFormat[]>([])
const itemsPerPage = ref(appState.itemsPerPage)
const loading = ref (false)
const doingUserSearch = ref(false)
const results = ref<Product[]>([])
const appServer = useAppServer()
const siteInfo = useSiteInfoStore()
const maxPages = computed(() => $q.screen.gt.sm ? 6 : 4)
const bestsellers = computed(() => siteInfo.itemGroupWithName('bestsellers'))
const authCenter = getInstance()

const isAdmin = computed(() => authCenter.loggedIn && authCenter.isSiteAdmin())

useMeta({ title: 'Search' })

watch(itemsPerPage, (newVal) => {
  appState.setItemsPerPage(newVal)
  searchPagination.value.rowsPerPage = newVal
  doSearch()
})

watch(() => route.query, (newVal) => {
  console.log('route query changed', newVal)
  parseQuery(route)
  doSearch()
})


onBeforeRouteUpdate(async (to, from) => {
  if (from.query !== to.query) {
    parseQuery(to)
    doSearch()
  }
})

onBeforeMount(() => {
  parseQuery(route)
})

onMounted(() => {
  if (searchParams.value.hasParams) {
    rawQueryString.value = searchParams.value.searchString
    doSearch()
  }
})

function parseQuery(loc: RouteLocation) {
  searchParams.value.parseQueryLocation(loc.query)
  if (searchParams.value.formatIds.length > 0) {
    selectedFilters.value = searchParams.value.formatIds.map((id) => siteInfo.formatWithIdOrDefault(id))
  }
}

const columns: QTableColumnsType = [
  {
    name: 'product',
    field: (row) => row.name,
    required: true,
    label: 'product',
  }
]

function formatForProduct(product: Product): ItemFormat {
  return siteInfo.formatWithIdOrDefault(product.formatId||0)
}

function lastPageNumber() {
  return Math.ceil(searchPagination.value.rowsNumber / searchPagination.value.rowsPerPage)
}

watch([() => searchPagination.value.page, () => searchPagination.value.rowsPerPage], () => {
  if (loading.value || doingUserSearch.value) return
  searchParams.value.page = searchPagination.value.page
  router.push({ query: searchParams.value.toQueryParams() })
})

function matchDisplay() {
  const page = searchPagination.value.page
  const rowsPerPage = searchPagination.value.rowsPerPage
  const totalRows = searchPagination.value.rowsNumber
  let rowsShown = page * rowsPerPage
  if (rowsShown > totalRows) rowsShown = totalRows
  return `<span class="hide-on-phone">Showing </span>${((page - 1) * rowsPerPage) + 1  }-${rowsShown} of ${totalRows} <span class="hide-on-phone">matching product${totalRows > 1 ? 's' : ''}</span>`
}

function clearSearchTerms() {
  searchParams.value.searchString = ''
  doUserSearch()
}

function doFilterChange(filters: ItemFormat[]) {
  selectedFilters.value = filters
  searchParams.value.formatIds = filters.map((f) => f.formatId)
  doUserSearch()
}

function doUserSearch() {
  doingUserSearch.value = true
  searchParams.value.searchString = rawQueryString.value
  router.push({ query: searchParams.value.toQueryParams() })
  doingUserSearch.value = false
}

function doSearch() {
  loading.value = true
  searchPagination.value.page = searchParams.value.page
  appServer.performSearch(searchParams.value.toSearchParams(appState.itemsPerPage))
    .then((result) => {
      results.value = result.items
      searchPagination.value.rowsNumber = result.totalRowsMatched
    })
    .finally(() => {
      loading.value = false
    })
}

const onRequest: QTableProps['onRequest'] = (params) => {
  console.log(`onRequest: ${JSON.stringify(params)}`)
}

async function addAllToCart() {
  const cartStore = useCartStore()
  let args: AddCartArgs[] = results.value.map((product) => {
    return { itemId: product.itemId as number, quantity: 1, add: true }
  })
  try {
    await cartStore.bulkAddToCart(args)
    router.push({ name: 'Cart' })
  } catch (e) {
    useErrorService().onError(toAlliedError(e))
  }
}

const addToCart = async (product: Product) => {
  const cartStore = useCartStore()
  if (product.itemId === undefined) throw new Error('invalid product') // should never happen
  $q.loading.show({ delay: 1000 })
  try {
    await cartStore.addToCart({ itemId: product.itemId, quantity: 1 })
  } catch (error) {
    useErrorService().onError(toAlliedError(error))
  } finally {
    $q.loading.hide()
  }
}
</script>

<style lang="scss" scoped>
.sp-search-field {
  @media (max-width: $breakpoint-xs-max) {
    max-width: 190px;
  }
}
.search-page {
  @media (min-width: $breakpoint-sm-max) {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>

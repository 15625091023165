<template>
  <div class="q-mb-lg">
    <q-table v-if="!loading" :rows="results.items" :columns="columns" row-key="itemId" :loading="loading"
    hide-header :rows-per-page-options="appState.itemsPerPageOptions" v-model:pagination="pagination"
    @request="onRequest" grid class="product-table">
      <template v-slot:loading>
        <q-inner-loading showing color="accent" />
      </template>
      <template v-slot:no-data>
        <div class="full-width row flex-center no-data text-accent q-gutter-sm" v-show="!loading">
          <div class="text-h5">No products are available in the selected category.</div>
          <div class="text-body1">Have a suggestion or specific need? <router-link to="/about/contact">Let us know</router-link></div>
        </div>
      </template>
      <template v-slot:top>
        <div id="match-count-desc" class="text-subtitle1" v-if="results.totalRowsMatched > 0">
          {{  matchDisplay() }}
        </div>
        <q-space />
        <div v-if="pagination.rowsNumber||0 > 0" class="nobr">
          <div class="row items-center justify-center">
            <div class="col-auto">Items per page: </div>
            <div class="col">
              <q-select
              v-model="itemsPerPage"
              :options="appState.itemsPerPageOptions"
              dense
              options-dense
              outlined
              class="q-ml-sm" />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item="props">
        <div class="product-box items-center q-ma-sm" :id="`product-${props.row.itemId}`">
          <router-link :to="{ name: 'ProductDetails', params: { itemId: props.row.itemId }}">
            <div class="q-mb-sm q-mx-md text-center">
              <q-img
                v-if="!props.row.hasImage"
                src="/images/product/noImage.png"
              />
              <product-image v-else :product="props.row" :format="formatForProduct(props.row)" :type="ImageType.Listing" />
            </div>
          </router-link>
          <div class="text-center wrap">
              <router-link :to="{ name: 'ProductDetails', params: { itemId: props.row.itemId }}">
                {{ props.row.name }} <span class="font-h6">&nbsp;{{ userStore.priceString(props.row) }}</span>
                <span v-if="props.row.customizable"><br><name-drop-badge /></span>
              </router-link>
            </div>
            <div class="add-to-cart text-center">
              <q-btn
                size="md"
                flat
                v-if="props.row.inStock"
                @click="addToCart(props.row)"
                >Add to Cart
              </q-btn>
              <strong v-else class="text-red">Out of stock</strong>
            </div>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="col" style="display: inline;">&nbsp;</div>
        <div class="col-auto text-center justify-center">
          <q-pagination
            v-model="pagination.page"
            :min="1"
            :max="lastPageNumber()"
            :max-pages="maxPages"
            :per-page="pagination.rowsPerPage"
            boundary-numbers
            boundary-links
            direction-links
            ellipses
          />
        </div>
        <div class="col">&nbsp;</div>
      </template>
    </q-table>
    <div v-else>
      <q-skeleton type="text" width="100px" class="text-subtitle1 q-my-md" />
      <div>
        <div class="row">
          <div class="col-auto">
            <product-skeleton />
          </div>
          <div class="col-auto">
            <product-skeleton />
          </div>
          <div class="col-auto">
            <product-skeleton />
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <product-skeleton />
          </div>
          <div class="col-auto">
            <product-skeleton />
          </div>
          <div class="col-auto">
            <product-skeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, watch, onBeforeMount, computed } from 'vue'
import { useQuasar, QTableProps } from 'quasar'
import { useRouter, useRoute } from 'vue-router'
import { useAppInfoStore } from 'src/stores/appState'
import { useCartStore } from 'src/stores/cart'
import { ItemFormat, Product, ProductSearchParams, ProductSearchResults, TagCollection } from '@nowallied/quasar-app-extension-na-core/src/models'
import { FetchParameters, defaultFetchParams, useAppServer } from 'src/boot/AppServer'
import { toAlliedError, useErrorService } from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import { QTableColumnsType } from '@nowallied/quasar-app-extension-na-core/src/quasar/QTableColumn'
import ProductImage from './ProductImage.vue'
import ProductSkeleton from './ProductSkeleton.vue'
import NameDropBadge from './NameDropBadge.vue'
import { ImageType } from 'src/utils/ImageUtils'
import { singleParamString } from 'app/na-core/src/util/routes'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { useUserStore } from 'src/stores/userStore'

/** Precedence is: collection, searchParams, format */
const props = defineProps({
  /** if specified, will show the members of the collection */
  collection: { type: Object as PropType<TagCollection>, required: false },
  /** shows items in the specified format batched by  */
  format: { type: Object as PropType<ItemFormat>, required: false },
  /** if true, will fetch records without any search critera */
  allowEmpty: { type: Boolean, default: false },
})

const columns: QTableColumnsType = [
  {
    name: 'product',
    field: (row) => row.name,
    required: true,
    label: 'product',
  }
]

const $q = useQuasar()
const $router = useRouter()
const $route = useRoute()
const appState = useAppInfoStore()
const siteInfo = useSiteInfoStore()
const userStore = useUserStore()
const appServer = useAppServer()
const searchOnly = ref(false)
// const { searchParams, itemsPerPage } = storeToRefs(appState)
const itemsPerPage = ref(appState.itemsPerPage)
const results = ref<ProductSearchResults>(new ProductSearchResults())
// this should be used for pagination
const fetchOptions: FetchParameters = { ...defaultFetchParams }
const loading = ref(true)
const ignorePageChanged = ref(false)
const ignoreSearchParamChange = ref(false)
const maxPages = computed(() => $q.screen.gt.sm ? 6 : 4)

type RequiredProperties<T, P extends keyof T> = Omit<T, P> & Required<Pick<T, P>>;
type QPagination = NonNullable<QTableProps['pagination']>
type QPaginationRequired = RequiredProperties<QPagination, 'page' | 'rowsPerPage' | 'rowsNumber'>
const pagination = ref<QPaginationRequired>({ sortBy: 'name', descending: false, page: 1, rowsPerPage: appState.itemsPerPage, rowsNumber: 0 })

watch(itemsPerPage, async (newVal) => {
  appState.setItemsPerPage(newVal)
  pagination.value.rowsPerPage = newVal
  fetchOptions.count = newVal
  fetchOptions.offset = 0
  await adjustPage()
})

watch([() => pagination.value.rowsPerPage], () => {
  if (pagination.value.rowsPerPage) {
    itemsPerPage.value = pagination.value.rowsPerPage
    appState.setItemsPerPage(pagination.value.rowsPerPage)
  }
})

watch([() => $route.query], async () => {
  adjustPage()
})

watch([() => pagination.value.page], async () => {
  if (loading.value || ignorePageChanged.value) return
  ignorePageChanged.value = true

  fetchOptions.offset = ((pagination.value.page||1) - 1) * (pagination.value.rowsPerPage||appState.itemsPerPage)
  const query: Record<string|number, string> = {}
  query.page = pagination.value.page.toString()
  $router.push({
    path: $route.path,
    query,
  })
  await fetchResult()
  ignorePageChanged.value = false
})

function lastPageNumber() {
  return Math.ceil((pagination.value.rowsNumber || 0) / (pagination.value.rowsPerPage || 10))
}

function matchDisplay() {
  const page = pagination.value.page||1
  const rowsPerPage = pagination.value.rowsPerPage||appState.itemsPerPage
  const totalRows = pagination.value.rowsNumber||0
  let rowsShown = page * rowsPerPage
  if (rowsShown > totalRows) rowsShown = totalRows
  return `Showing ${((page - 1) * rowsPerPage) + 1  }-${rowsShown} of ${totalRows} matching product${totalRows > 1 ? 's' : ''}`
}


function formatForProduct(product: Product): ItemFormat {
  return siteInfo.formatWithIdOrDefault(product.formatId||0)
}

async function adjustPage() {
  // update the pagination and refetch
  let page = 1
  if ($route.query.page) {
    page = parseInt(singleParamString($route.query.page) || '1', 10)
    if (page < 1) page = 1
  }
  pagination.value.page = page
  fetchOptions.offset = (page - 1) * itemsPerPage.value
  await fetchResult()
}

const addToCart = async (product: Product) => {
  const cartStore = useCartStore()
  if (product.itemId === undefined) throw new Error('invalid product') // should never happen
  $q.loading.show({ delay: 1000 })
  try {
    await cartStore.addToCart({ itemId: product.itemId, quantity: 1 })
  } catch (error) {
    useErrorService().onError(toAlliedError(error))
  } finally {
    $q.loading.hide()
  }
}

async function fetchResult() {
  try {
    loading.value = true
    ignoreSearchParamChange.value = true
    if (props.collection) {
      if (props.format) fetchOptions.formatIds = [props.format.formatId]
      results.value = await appServer.productsForCollection(props.collection.collectionId, fetchOptions)
    } else if (props.format) {
      // if (isEqual(fetchOptions, lastFetchOptions))
      //   return
      fetchOptions.formatIds = [props.format.formatId]
      results.value = await appServer.productsForFormat(props.format, fetchOptions)
    } else if (props.allowEmpty) {
      const params = new ProductSearchParams()
      params.count = appState.itemsPerPage
      params.random = true
      params.hasImage = true
      params.orderable = true
      results.value = await appServer.performSearch(params)
    } else {
      useErrorService().onError(toAlliedError('programmer error: must supply a collection, searchParams, or format'))
    }
    const offset = fetchOptions.offset || 0
    if ((results.value.items.length < 1 && pagination.value.page||1 > 1)
      || (results.value.totalRowsMatched < offset)) {
        // asked for a page past the last one. send to page 1
        console.log('invalid page request, sending back to zero')
        await $router.push($route.path)
        return
      }
    pagination.value.rowsNumber = results.value.totalRowsMatched
    pagination.value.page = offset === 0 ? 1 : Math.floor(offset / itemsPerPage.value) + 1
  } finally {
    loading.value = false
    ignoreSearchParamChange.value = false
  }
}

// handler for when pagination event happens
const onRequest: QTableProps['onRequest'] = (params) => {
  // console.log('ProductList onRequest triggered')
  const { page, rowsPerPage } = params.pagination
  appState.setItemsPerPage(rowsPerPage)
  fetchOptions.count = rowsPerPage
  fetchOptions.offset = ((page || 1) - 1) * fetchOptions.count
  pagination.value.rowsPerPage = rowsPerPage
  const query: Record<string|number, string> = {}
  query.page = page.toString()
  $router.push({
    path: $route.path,
    query,
  })

  void fetchResult().then(() => {
    pagination.value.rowsNumber = results.value.totalRowsMatched
    pagination.value.page = ((fetchOptions.offset || 0) / results.value.items.length) + 1
  })
}

onBeforeMount(() => {
  fetchOptions.count = appState.itemsPerPage
  searchOnly.value = props.collection === undefined
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  adjustPage().catch(() => {})
})

</script>

<style lang="scss" scoped>
.card-content {
  display: flex;
  align-items: center;
  height: 213px;
  background-color: #FFFFFF;
  img {
    margin: auto auto;
  }
}
.product-box {
  padding: 10px 10px;
  width: 300px;
}
.prod-header {
  width: 100%;
  max-width: 1340px;
}
.product-table {
  max-width: 1340px;
}
.item-format {
  font-style: italic;
}
</style>

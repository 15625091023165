import axios from 'axios'
import { appLog, serviceLog } from './Logging'
import {
  AlliedBaseError,
  AlliedError,
  AlliedErrorCode,
  HttpError,
  isAlliedError,
  isRawRestError,
  NetworkError,
  RawRestError,
  RestError,
} from './Errors'

export type AppError = AlliedError | Error | string

export enum EventKind {
  Error = 'ERROR',
  Warn = 'WARN',
  Info = 'INFO',
  Unknown = 'UNKNOWN',
}

type ErrorHandler = (kind: EventKind, error: AppError | unknown) => void

/// ECMAScript standard gurantees this works
export function isJSError(obj: unknown): obj is Error {
  return Object.prototype.toString.call(obj) === '[object Error]'
}

/// returns true for any supported by convertToAlliedError()
export function isAppError(obj: unknown): obj is AppError {
  if (obj === null) return false
  return (
    axios.isAxiosError(obj) ||
    isAlliedError(obj) ||
    obj instanceof Error ||
    typeof obj === 'string'
  )
}

/// converts unknown object to an AppError
export function toAlliedError(error: unknown): AppError {
  if (isAppError(error)) {
    return error
  }
  return new AlliedBaseError(
    AlliedErrorCode.Unknown,
    `unknown error: ${JSON.stringify(error)}`
  )
}

/// converts the input to an appropriate AlliedError
export function convertToAlliedError(error: unknown): AlliedBaseError {
  if (error instanceof AlliedBaseError) return error
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (isRawRestError(error.response.data))
        return new RestError(error.response.data as RawRestError)
      if (error.response.data && error.response.data.errors) {
        return new HttpError(error.response, error.response.data.errors[0])
      }
      return new HttpError(error.response, error.message)
    }
    if (error.request) return new NetworkError(error)
    return new AlliedBaseError(AlliedErrorCode.Axios, error.message)
  }
  if (typeof error === 'string')
    return new AlliedBaseError(AlliedErrorCode.Unknown, error)
  if (isJSError(error))
    return new AlliedBaseError(AlliedErrorCode.Unknown, error.message)
  return new AlliedBaseError(AlliedErrorCode.Unknown, JSON.stringify(error))
}

/// class wrapping static error handlilng functions
export class ErrorService {
  private _errorHandler?: ErrorHandler

  /// installs a handler that gets called for all errors. Ideal for displaying errors to user
  installHandler(handler: ErrorHandler) {
    this._errorHandler = handler
  }

  onError(err: AppError) {
    const theError = convertToAlliedError(err)
    if (this._errorHandler === undefined)
      serviceLog.warn(`unknown error: ${JSON.stringify(theError)}`)
    else this._errorHandler(EventKind.Error, err)
  }

  onWarn(err: AppError) {
    const theError = convertToAlliedError(err)
    if (this._errorHandler === undefined)
      serviceLog.warn(`unknown warning: ${JSON.stringify(theError)}`)
    else this._errorHandler(EventKind.Warn, err)
  }

  onInfo(err: AppError) {
    const theError = convertToAlliedError(err)
    if (this._errorHandler === undefined)
      serviceLog.warn(`unknown info: ${JSON.stringify(theError)}`)
    else this._errorHandler(EventKind.Info, err)
  }

  constructor() {
    appLog.info('ErrorService started')
    window.onerror = (
      message: string | Event,
      url?: string,
      lineNo?: number,
      columnNo?: number,
      error?: Error
    ): boolean => {
      if (isAppError(error)) {
        this.onError(error)
      } else if (typeof message === 'string') {
        this.onError(convertToAlliedError(message))
      } else {
        appLog.warn(`got event error message: ${JSON.stringify(error)}`)
      }
      return true
    }
  }
}

declare global {
  interface Window {
    alliedErrorService?: ErrorService
  }
}

export function useErrorService(): ErrorService {
  if (window.alliedErrorService === undefined) {
    window.alliedErrorService = new ErrorService()
  }
  return window.alliedErrorService
}

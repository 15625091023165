const devBase = '/v1' // 'https://nav2.mbtcats.com/v1' // 'http://localhost:8080/v1'
export const BASE_URL: string = process.env.NODE_ENV === 'production' ? '/api/v1' : devBase
export const adminUrl = 'https://admin.lilybart.com/'
export const getSiteInfo = `${BASE_URL}/info`
export const getFeaturedCategory = `${BASE_URL}/featured`
export const productsUrl = `${BASE_URL}/product`
export const cartUrl = `${BASE_URL}/cart`
export const collectionBaseUrl = `${BASE_URL}/product/collection`
export const saveAddress = `${BASE_URL}/account/addresses/save`
export const stylesheetUrl = `${BASE_URL}/style`
export const cdnBaseUrl = 'https://img.nowallied.com'

import Currency from 'currency.js'
import { Entity } from './Entity'

export interface ShipMethodI extends Entity {
  shipmethodId: number
  carrier: string
  name: string
  displayName: string
  engine: string
  enabled: boolean
  sortOrder: number
  freeThreshold: string
  wholesalefreeThreshold: string
  digitalEnabled: boolean
}

export class ShipMethod implements Entity {
  readonly entityType = 'ShipMethod'

  shipmethodId: number
  carrier: string
  name: string
  displayName: string
  engine: string
  enabled: boolean
  sortOrder: number
  freeThreshold: Currency
  wholesalefreeThreshold: Currency
  digitalEnabled: boolean

  constructor(data: ShipMethodI) {
    this.shipmethodId = data.shipmethodId
    this.carrier = data.carrier
    this.name = data.name
    this.displayName = data.displayName
    this.enabled = data.enabled
    this.engine = data.engine
    this.sortOrder = data.sortOrder
    this.freeThreshold = Currency(data.freeThreshold)
    this.wholesalefreeThreshold = Currency(data.wholesalefreeThreshold)
    this.digitalEnabled = data.digitalEnabled
  }

  get idenity(): number {
    return this.shipmethodId
  }

  toJSON(): string {
    const { freeThreshold, wholesalefreeThreshold, ...data } = this
    const ft = freeThreshold.toString()
    const wt = wholesalefreeThreshold.toString()
    return JSON.stringify({
      freeThreshold: ft,
      wholesalefreeThreshold: wt,
      ...data,
    })
  }
}

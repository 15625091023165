import { defineStore } from 'pinia'
import {
  User,
  UserI,
  UserAddress,
  UserAddressI,
  RemoteUserWithAddresses,
  SiteOrderI,
  SiteOrder,
  parseRemoteUser,
  Product,
} from '@nowallied/quasar-app-extension-na-core/src/models'
import { useAppServer } from 'src/boot/AppServer'
import axios, { AxiosRequestConfig } from 'axios'
import { isAlliedError } from '@nowallied/quasar-app-extension-na-core/src/services/Errors'
import { toAlliedError } from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import { useSiteInfoStore } from './siteInfo'
import { isProxy, toRaw } from 'vue'
import currency from 'currency.js'

// type IncomingUserInfo = {
//   user: UserI
//   addresses: UserAddressI[]
// }

export interface AdressErrorDetails {
  /// the property of UserAddress the error is about
  field: string
  /// the problem with the field
  message: string
}

interface ListOrdersResponse {
  totalCount: number
  offset: number
  orders: SiteOrderI[]
}

export interface SiteOrderList {
  totalCount: number
  orders: SiteOrder[]
}

export interface SaveUserParams {
  userId: number
  firstName?: string
  lastName?: string
  businessName?: string
  mobilePhone?: string
  billingFirstName?: string
  billingLastName?: string
  billingAddressId?: number
  emailMarketing?: boolean
  textOrderStatus?: boolean
}

/// response from saving an address
export interface AddressValidationResponse {
  /// the user with addresses
  user: UserI
  /// the id of the address that was added/updated
  addressId: number
  /// true if the address was verified and added to the user
  verified: boolean
  /// An optional cleaned up version of the address to prompt the user to select
  suggestedAddress?: UserAddressI
  /// The original submitted address
  submittedAddress?: UserAddressI
  /// if there were any errors, details on what the error(s) were
  errors?: AdressErrorDetails[]
}

interface IUserStoreState {
  user?: User
  addresses: UserAddress[]
}

export const useUserStore = defineStore({
  id: 'userStore',
  state: (): IUserStoreState => ({ addresses: [] }),
  getters: {
    addressWithId: (state) => {
      return (addressId: number) => {
        console.log('returning awi function')
        return state.addresses.find((a) => a.addressId === addressId)
      }
    },
    emptyResponse: (state) => {
      return {
        user: state.user,
        addressId: 0,
        verified: false,
        errors: [],
      } as AddressValidationResponse
    },
    getsWholesaleDiscount: (state) => {
      return state.user?.wholesale || false
    },
  },
  actions: {
    async fetchUser() {
      const res = await useAppServer().axios.get('/account')
      const data = res.data as RemoteUserWithAddresses
      this.user = new User(data.user)
      this.addresses.splice(0, this.addresses.length)
      data.addresses.forEach((a) => this.addresses.push(new UserAddress(a)))
      this.user.addresses = this.addresses
      return this.user
    },
    priceString(product: Product) {
      if (!this.user?.wholesale) return '$' + product.basePrice
      const priceList = useSiteInfoStore().priceListWithId(product.priceListId || 0)
      const pricePoint = priceList.pricePointForQuantity(1)
      return currency(pricePoint.price).multiply(priceList.wholesaleDiscount).divide(100).format()
    },
    async markedOnboarded() {
      if (!this.user) throw new Error('not logged in')
      const config: AxiosRequestConfig = {
        method: 'post',
        url: '/account/onboarded',
      }
      await useAppServer().axios.request(config)
    },
    async saveUser(params: SaveUserParams) {
      const config: AxiosRequestConfig = {
        method: 'put',
        url: '/account',
        data: params,
      }
      const res = await useAppServer().axios.request<RemoteUserWithAddresses>(config)
      const updatedUser = parseRemoteUser(res.data)
      this.user = updatedUser
      useSiteInfoStore().user = updatedUser
    },
    async getOrder(identifier: string): Promise<SiteOrder | undefined> {
      const config: CacheRequestConfig = {
        method: 'get',
        url: `/account/order/${identifier}`,
      }
      try {
        const res = await useAppServer().axios.request<SiteOrderI>(config)
        return new SiteOrder(res.data)
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) return undefined
        }
        throw e
      }
    },
    async getOrders(offset: number, count: number): Promise<SiteOrderList> {
      const config: CacheRequestConfig = {
        method: 'get',
        url: '/account/orders',
        params: { start: offset, count },
      }
      const res = await useAppServer().axios.request<ListOrdersResponse>(config)
      const orders = res.data.orders.map((o) => new SiteOrder(o))
      return { totalCount: res.data.totalCount, orders }
    },
    async addOrderToCart(order: SiteOrder): Promise<void> {
      const config: CacheRequestConfig = {
        method: 'put',
        url: `/account/order/${order.orderIdentifier}`,
      }
      try {
        await useAppServer().axios.request(config)
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) return undefined
        }
        throw e
      }
    },
    async saveAddress(
      address: UserAddress,
      isVerified = false
    ): Promise<AddressValidationResponse> {
      if (!this.user) throw new Error('not logged in')
      if (isProxy(address)) address = toRaw(address)
      const config: AxiosRequestConfig = {
        method: 'post',
        url: '/account/address/save',
        data: { verified: isVerified, address },
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await useAppServer().axios.request<AddressValidationResponse>(config)
      const response = res.data
      if (response.verified && response.submittedAddress && response.user.addresses) {
        this.user = new User(response.user)
        this.addresses.splice(0, this.addresses.length)
        response.user.addresses.forEach((a) => this.addresses.push(new UserAddress(a)))
      }
      return response
    },
    async deleteAddress(address: UserAddress): Promise<void> {
      if (!this.user) throw new Error('not logged in')
      try {
        const { data } = await useAppServer().axios.delete<RemoteUserWithAddresses>(
          `/account/addresses/${address.addressId}`
        )
        const updatedUser = new User({
          addresses: data.addresses,
          ...data.user,
        } as UserI)
        this.user = updatedUser
      } catch (e) {
        if (isAlliedError(e)) {
          throw toAlliedError(e)
        }
        throw e
      }
    },

    async setDefaultAddress(address: UserAddress) {
      if (!this.user) throw new Error('not logged in')
      try {
        const { data } = await useAppServer().axios.put<RemoteUserWithAddresses>(
          `/account/addresses/default/${address.addressId}`
        )
        const updatedUser = new User({
          addresses: data.addresses,
          ...data.user,
        } as UserI)
        this.user = updatedUser
      } catch (e) {
        if (isAlliedError(e)) {
          throw toAlliedError(e)
        }
        throw e
      }
    },
  },
})

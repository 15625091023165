import { Entity } from '.'

export interface ProductImageI {
  imageId: number
  itemId: number
  caption: string
  cdnPath: string
  sortOrder: number
  updatedAt: number
}

export class ProductImage implements Entity, ProductImageI {
  readonly entityType = 'ProductImage'

  imageId = 0
  itemId = 0
  caption = ''
  cdnPath = ''
  sortOrder = 0
  updatedAt: number

  constructor(data: ProductImageI) {
    this.imageId = data.imageId
    this.itemId = data.itemId
    this.caption = data.caption
    this.cdnPath = data.cdnPath
    this.sortOrder = data.sortOrder
    this.updatedAt = data.updatedAt
  }

  get idenity() {
    return this.imageId
  }
}

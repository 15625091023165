import { Entity } from './Entity'
import { z } from 'zod'

export const stockistSchema = z.object({
  stockistId: z.number().nonnegative(),
  siteId: z.number().nonnegative(),
  name: z.string(),
  url: z.string().optional(),
  formattedAddress: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip: z.string().optional(),
  country: z.string().min(2).max(2).default('US'),
  lat: z.string().optional(),
  lng: z.string().optional(),
  placeId: z.string().optional(),
  lastModified: z.union([z.date(), z.string()]).optional(),
  active: z.boolean().default(true),
  phone: z.string().optional(),
  contactName: z.string().optional(),
  notes: z.string().default(''),
})

export type StockistI = z.infer<typeof stockistSchema>

export class Stockist implements StockistI, Entity {
  readonly entityType = 'Stockist'

  stockistId = 0
  siteId = 0
  name = ''
  url?: string
  formattedAddress?: string
  city = ''
  state = ''
  zip?: string
  country = 'US'
  lat?: string
  lng?: string
  placeId?: string
  lastModified?: Date
  active = true
  notes = ''
  phone?: string
  contactName?: string

  constructor(data?: StockistI) {
    if (data === undefined) return
    Object.assign(this, data)
  }

  get idenity() {
    return this.stockistId
  }

  toJSON() {
    return {
      stockistId: this.stockistId,
      siteId: this.siteId,
      name: this.name,
      url: this.url,
      formattedAddress: this.formattedAddress,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
      lat: this.lat,
      lng: this.lng,
      placeId: this.placeId,
      lastModified: this.lastModified,
      active: this.active,
      notes: this.notes,
      phone: this.phone,
      contactName: this.contactName,
    }
  }
  static schema = stockistSchema
}

import { getInstance } from '@nowallied/quasar-app-extension-na-core/src/services/AuthCenter'
import { route } from 'quasar/wrappers'
import { useAppInfoStore } from 'src/stores/appState'
import { useCartStore } from 'src/stores/cart'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteLocation,
  Router,
} from 'vue-router'
import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let theRouter: Router

export default route((/* { store, ssrContext } */) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory

  theRouter = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  // this forces loadSiteData to have completed before the site is loaded
  theRouter.beforeEach(async (to) => {
    const store = useSiteInfoStore()
    await store.loadSiteData()
    if (to.path !== '/onboard' && store.user && !store.user.onboardingComplete) {
      return { name: 'Onboard' }
    }
    if (to.matched.some((record) => record.meta.requireLogin)) {
      const ac = getInstance()
      if (!ac.loggedIn.value) {
        const appInfo = useAppInfoStore()
        appInfo.loginDest = to.fullPath
        if (to.matched.some((record) => record.meta.sendToLoginPage)) {
          return { name: 'LoginPage' }
        }
        console.log(`set loginDest to ${appInfo.loginDest}`)
        ac.loginWithRedirect()
        return false
      }
      if (to.matched.some((record) => record.meta.requireCart)) {
        if (useCartStore().isEmpty) {
          return { name: 'Cart' }
        }
      }
    }
    return true
  })

  return theRouter
})

function routeDisplayName(route: RouteLocation): string {
  if (route.meta?.displayName) return route.meta.displayName
  if (typeof route.name === 'string') return route.name
  return ''
}

export { theRouter, routeDisplayName }

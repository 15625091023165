import { Entity } from './Entity'
import { ImageTagI } from './Product'

export interface SiteOrderItemImageDetailsI {
  greetingCard: boolean
  imageTag: ImageTagI
  thumbTag: ImageTagI
}

export interface SiteOrderItemI {
  orderitemId: number
  orderId: number
  itemId: number
  quantity: number
  price: string
  name: string
  details: string
  customText: string
  imageDetails?: SiteOrderItemImageDetailsI
}

export class SiteOrderItem implements Entity {
  readonly entityType = 'SiteOrderItem'

  orderitemId: number
  orderId: number
  itemId: number
  quantity: number
  price: string
  name: string
  details: string
  customText: string
  imageDetails?: SiteOrderItemImageDetailsI

  constructor(data: SiteOrderItemI) {
    this.orderitemId = data.orderitemId
    this.orderId = data.orderId
    this.itemId = data.itemId
    this.quantity = data.quantity
    this.price = data.price
    this.name = data.name
    this.details = data.details
    this.customText = data.customText
    this.imageDetails = data.imageDetails
  }

  get idenity(): number {
    return this.orderitemId
  }

  toJSON(): string {
    return JSON.stringify(this)
  }
}

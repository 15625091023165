import { CustomError } from 'ts-custom-error'
import { AxiosError, AxiosResponse } from 'axios'

export enum AlliedErrorCode {
  /// server returned our custom error message
  RestError,
  /// an app server request responded with a 4xx or 5xx error
  Http,
  /// the network attempt failed., will be instance of NetworkError with the AxiosError available
  Network,
  /// configuration error with Axios. Message is the only useful information
  Axios,
  /// User requested something that was not found. message should detail what was not found
  NotFound,
  /// unknown type, likely just with a message
  Unknown,
}

export enum HttpErrorCode {
  NotFound,
  Unauthorized,
  Unprocessable,
  BadRequest,
  ServerError,
  Other,
}

function statusToHttpErrorCode(status: number): HttpErrorCode {
  switch (status) {
    case 400:
      return HttpErrorCode.BadRequest
    case 401:
      return HttpErrorCode.Unauthorized
    case 404:
      return HttpErrorCode.NotFound
    case 422:
      return HttpErrorCode.Unprocessable
    default:
      if (status >= 500) return HttpErrorCode.ServerError
      return HttpErrorCode.Other
  }
}
export interface AlliedError extends CustomError {
  readonly code: AlliedErrorCode
}

export function isAlliedError(object: unknown): object is AlliedError {
  return (object as AlliedError).code != undefined
}

export class AlliedBaseError extends CustomError implements AlliedError {
  private _code: AlliedErrorCode

  public constructor(code: AlliedErrorCode, message?: string) {
    super(message)
    this._code = code
  }

  public get code(): AlliedErrorCode {
    return this._code
  }
}

export interface RawRestError {
  type: string
  code: string
  message: string
  userMessage: string
}

export function isRawRestError(data: unknown): data is RawRestError {
  const raw = data as RawRestError
  return raw && raw.type === 'restError'
}

export class RestError extends AlliedBaseError {
  rawError: RawRestError

  constructor(data: RawRestError) {
    super(AlliedErrorCode.RestError, data.userMessage)
    this.rawError = data
  }

  get message() {
    return this.rawError.userMessage
  }
}

export function isRestError(data: unknown): data is RestError {
  return data instanceof RestError
}

export class HttpError extends AlliedBaseError {
  private _response: AxiosResponse<unknown, unknown>
  private _httpCode: HttpErrorCode

  public constructor(
    response: AxiosResponse<unknown, unknown>,
    message?: string
  ) {
    super(AlliedErrorCode.Http, message)
    this._response = response
    this._httpCode = statusToHttpErrorCode(response.status)
  }

  public get response(): AxiosResponse {
    return this._response
  }
  public get statusCode(): HttpErrorCode {
    return this._httpCode
  }
}

export function isHttpError(data: unknown): data is HttpError {
  return data instanceof HttpError
}

export class NetworkError extends AlliedBaseError {
  private _axiosError: AxiosError

  public constructor(error: AxiosError, message?: string) {
    super(AlliedErrorCode.Network, message)
    this._axiosError = error
  }

  public get axiosError(): AxiosError {
    return this.axiosError
  }
}

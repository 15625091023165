/* eslint max-classes-per-file: "off" */
import { z } from 'zod'

// prettier-ignore
export const hostSchema = z.object({
  hostId: z.number().int().positive(),
  name: z.string().min(1),
})

export type SiteHostI = z.infer<typeof hostSchema>

export class SiteHost implements SiteHostI {
  hostId: number
  name: string

  constructor(hostId: number, name: string) {
    this.hostId = hostId
    this.name = name
  }
}

export const siteSchema = z.object({
  siteId: z.number().nonnegative(),
  name: z.string().min(1),
  code: z.string().min(1),
  allowGuests: z.boolean(),
  allowSignup: z.boolean(),
  signupRequiresApproval: z.boolean(),
  defaultShippingId: z.number(),
  freeshipMinimum: z.number().nonnegative(),
  freeshipMinimumWholesale: z.number().nonnegative(),
  digitalWalletEnabled: z.boolean(),
  hosts: hostSchema.array(),
  baseHandling: z.string().default('0'),
})

export type SiteI = z.infer<typeof siteSchema>

const emptySite = {
  siteId: 0,
  name: '',
  code: '',
  allowGuests: false,
  allowSignup: false,
  signupRequiresApproval: false,
  defaultShippingId: 0,
  freeshipMinimum: 0,
  freeshipMinimumWholesale: 0,
  digitalWalletEnabled: false,
  hosts: [],
  baseHandling: '0',
} as SiteI

export class Site implements SiteI {
  readonly siteId: number
  name: string
  readonly code: string
  allowGuests: boolean
  allowSignup: boolean
  digitalWalletEnabled = false
  signupRequiresApproval: boolean
  defaultShippingId: number
  freeshipMinimum: number
  freeshipMinimumWholesale: number
  hosts: SiteHostI[]
  baseHandling: string

  constructor(data: SiteI = emptySite, validate = false) {
    this.siteId = data.siteId
    this.name = data.name
    this.code = data.code
    this.allowGuests = data.allowGuests
    this.allowSignup = data.allowSignup
    this.signupRequiresApproval = data.signupRequiresApproval
    this.defaultShippingId = data.defaultShippingId
    this.freeshipMinimum = data.freeshipMinimum
    this.freeshipMinimumWholesale = data.freeshipMinimumWholesale
    this.digitalWalletEnabled = data.digitalWalletEnabled
    this.hosts = data.hosts || []
    this.baseHandling = data.baseHandling
    if (validate) {
      siteSchema.parse(this)
    }
  }

  revertTo(data: SiteI) {
    this.name = data.name
    this.allowGuests = data.allowGuests
    this.allowSignup = data.allowSignup
    this.signupRequiresApproval = data.signupRequiresApproval
    this.baseHandling = data.baseHandling
    this.hosts = data.hosts || []
  }

  updateTo(site: SiteI) {
    this.name = site.name
  }

  validate(): boolean {
    return siteSchema.safeParse(this).success
  }
}

export function isSite(item: unknown): item is SiteI {
  return siteSchema.safeParse(item).success
}

export const adminSiteSchema = siteSchema.extend({
  properties: z.record(z.string()),
  canonicalDomain: z.string().optional(),
})

export type AdminSiteI = z.infer<typeof adminSiteSchema>

const emptyAdminSite = { ...emptySite, properties: {} }

export class AdminSite extends Site implements AdminSiteI {
  properties: Record<string, string>
  canonicalDomain: string | undefined

  constructor(data: AdminSiteI = emptyAdminSite) {
    super(data)
    this.properties = data.properties
    this.canonicalDomain = data.canonicalDomain
  }

  updateTo(site: AdminSiteI) {
    super.updateTo(site)
    this.canonicalDomain = site.canonicalDomain
  }
}

import Currency from 'currency.js'
import { Entity, NamedEntity } from './Entity'
import { clone } from 'lodash'
import { z } from 'zod'
import { ProductImage, ProductImageI } from './ProductImage'

export const imageTagSchema = z.object({
  src: z.string(),
  srcset: z.string(),
  cssClass: z.string(),
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
})

export type ImageTagI = z.infer<typeof imageTagSchema>

// export interface ImageTagI {
//   src: string
//   srcset: string
//   cssClass: string
//   width: number
//   height: number
// }

export const formatDetailsSchema = z.object({
  formatId: z.number().nonnegative(),
  priceListId: z.number().nonnegative(),
  descriptionId: z.number().nonnegative(),
  details: z.string().optional(),
})

export type FormatDetailsI = z.infer<typeof formatDetailsSchema>

const weightRegex = /^\d*(\.\d{1,4})?$/

const baseProductSchema = z.object({
  itemId: z.number().nonnegative(),
  siteId: z.number().nonnegative(),
  name: z.string().min(1),
  descriptionId: z.number().nonnegative().nullable(),
  wholesaleDescriptionId: z.number().nonnegative().nullable(),
  priceListId: z.number().nonnegative().nullable(),
  formatId: z.number().nonnegative().nullable(),
  $descriptionName: z.string().optional(),
  $priceListName: z.string().optional(),
  $formatName: z.string().optional(),
  $tagsName: z.string().optional(),
  weight: z.string().regex(weightRegex),
  weightQuantity: z.number().nullable(),
  priority: z.number(),
  hasImage: z.boolean(),
  imageVertical: z.boolean(),
  usesInventory: z.boolean(),
  customizable: z.boolean(),
  searchOnly: z.boolean(),
  inStock: z.boolean(),
  greetingCard: z.boolean(),
  imageCdnPath: z.string(),
  details: z.string().optional(),
  imageTag: imageTagSchema.optional(),
  thumbTag: imageTagSchema.optional(),
  squareTag: imageTagSchema.optional(),
  listingTag: imageTagSchema.optional(),
  tagIds: z.number().array(),
  formats: formatDetailsSchema.array(),
})

/** interface for Product as JSON
 *
 * properties that start with a '$' are for convience, but are not
 * included when received from the server. They should be set by
 * the code that fetches the data from the server. They are undefined
 * by default.
 */
export interface ProductI {
  /** undefined is allowed for new objects where the server will generate the itemId */
  itemId: number | undefined
  siteId: number
  name: string
  descriptionId: number | null
  $descriptionName: string | undefined
  /*** sent by the server, derived by accessing an ItemDescription using descriptionId */
  wholesaleDescriptionId: number | null
  description: string
  wholesaleDescription: string | null
  priceListId: number | null
  $priceListName: string | undefined
  formatId: number | null
  $formatName: string | undefined
  weight: string
  weightQuantity: number | null
  priority: number
  fixedShipping: string | null
  orderable: string
  rush: string
  rushAmount: string | null
  quantityLabel: string | null
  hasImage: boolean
  imageVertical: boolean
  usesInventory: boolean
  customizable: boolean
  searchOnly: boolean
  tagIds: number[]
  $tagsName: string | undefined
  imageCdnPath: string
  greetingCard: boolean
  inStock: boolean
  imageTag?: ImageTagI
  thumbTag?: ImageTagI
  squareTag?: ImageTagI
  listingTag?: ImageTagI
  details?: string
  formats?: FormatDetailsI[]
  basePrice?: string
  secondaryImages?: ProductImageI[]
}

export enum RushType {
  Inherit = 'Inherit',
  None = 'None',
  Fixed = 'Fixed',
}

export const rushTypesWithCharge: RushType[] = [RushType.Fixed]

export enum OrderableType {
  Yes = 'Yes',
  No = 'No',
  Unavailable = 'Unavailable',
}

export const productSchema = baseProductSchema.extend({
  fixedShipping: z.instanceof(Currency),
  orderable: z.nativeEnum(OrderableType),
  rush: z.nativeEnum(RushType),
  rushAmount: z.instanceof(Currency),
})

const remoteProductSchema = productSchema.omit({
  fixedShipping: true,
  rushAmount: true,
  $descriptionName: true,
  $formatName: true,
  $priceListName: true,
  $tagsName: true,
  imageTag: true,
  thumbTag: true,
  squareTag: true,
  listingTag: true,
  details: true,
})

export class Product implements NamedEntity {
  itemId: number | undefined = undefined
  readonly entityType = 'Product'
  siteId = 0
  name = ''
  descriptionId: number | null = null
  wholesaleDescriptionId: number | null = null
  $descriptionName: string | undefined
  description = ''
  wholesaleDescription = ''
  priceListId: number | null = null
  $priceListName: string | undefined
  formatId: number | null = null
  $formatName: string | undefined
  weight = ''
  priority = 0
  weightQuantity: number | null = 1
  fixedShipping: Currency | null = null
  orderable: OrderableType = OrderableType.No
  rush: RushType = RushType.Inherit
  rushAmount: Currency | null = null
  quantityLabel: string | undefined = undefined
  hasImage = false
  imageVertical = false
  usesInventory = false
  customizable = false
  searchOnly = false
  tagIds: number[] = []
  $tagsName: string | undefined
  imageCdnPath = ''
  greetingCard = false
  inStock = false
  imageTag?: ImageTagI
  thumbTag?: ImageTagI
  squareTag?: ImageTagI
  listingTag?: ImageTagI
  details: string | undefined = undefined
  formats: FormatDetailsI[] = []
  basePrice = ''
  secondaryImages: ProductImage[] = []

  static createProduct() {
    return new Product({
      orderable: 'No',
      rush: 'Inherit',
      weight: '1',
      weightQuantity: 1,
      priority: 0,
      quantityLabel: '1',
      hasImage: false,
      imageVerical: false,
      usesInventory: false,
      customizable: false,
      searchOnly: false,
      greetingCard: false,
      imageCdnPath: '',
      formats: [],
      secondaryImages: [],
    } as unknown as ProductI)
  }

  constructor(args: ProductI | Product) {
    this.itemId = args.itemId
    this.siteId = args.siteId
    this.name = args.name
    this.descriptionId = args.descriptionId
    this.wholesaleDescriptionId = args.wholesaleDescriptionId
    this.description = args.description
    this.wholesaleDescription = args.wholesaleDescription || args.description
    this.priceListId = args.priceListId
    this.$descriptionName = args.$descriptionName
    this.$priceListName = args.$priceListName
    this.formatId = args.formatId
    this.$formatName = args.$formatName
    this.weight = args.weight
    this.weightQuantity = args.weightQuantity
    this.priority = args.priority
    this.quantityLabel = args.quantityLabel || undefined
    this.hasImage = args.hasImage
    this.imageVertical = args.imageVertical
    this.usesInventory = args.usesInventory
    this.customizable = args.customizable
    this.tagIds = Array.isArray(args.tagIds) ? [...args.tagIds] : []
    this.$tagsName = args.$tagsName
    this.imageCdnPath = args.imageCdnPath
    this.greetingCard = args.greetingCard
    this.inStock = args.inStock
    this.imageTag = clone(args.imageTag)
    this.thumbTag = clone(args.thumbTag)
    this.squareTag = clone(args.squareTag)
    this.listingTag = clone(args.listingTag)
    this.details = args.details
    this.formats = args.formats || []
    this.basePrice = args.basePrice || ''
    this.searchOnly = args.searchOnly
    this.secondaryImages =
      args.secondaryImages?.map((img) => new ProductImage(img)) || []

    if (isProduct(args)) {
      this.rushAmount = args.rushAmount
      this.fixedShipping = args.fixedShipping
      this.rush = args.rush
      this.orderable = args.orderable
    } else {
      this.rushAmount = Currency(args.rushAmount || 0)
      this.fixedShipping = Currency(args.fixedShipping || 0)
      try {
        this.rush = RushType[args.rush as keyof typeof RushType]
      } catch (error) {
        this.rush = RushType.Inherit
      }
      try {
        this.orderable =
          OrderableType[args.orderable as keyof typeof OrderableType]
      } catch (error) {
        this.orderable = OrderableType.No
      }
    }
    if (!Array.isArray(this.formats)) {
      this.formats = []
    }
  }

  get priceListDescription() {
    return `${this.$priceListName || '-'} (${this.priceListId || '-'})`
  }

  get formatName() {
    return this.$formatName || ''
  }
  set formatName(name: string) {
    this.$formatName = name
  }

  get priceListName() {
    return this.$priceListName || ''
  }
  set priceListName(name: string) {
    this.$priceListName = name
  }

  get descriptionName() {
    return this.$descriptionName || ''
  }
  set descriptionName(name: string) {
    this.$descriptionName = name
  }

  get tagsName() {
    return this.$tagsName || ''
  }
  set tagsName(name: string) {
    this.$tagsName = name
  }

  get isGreetingCard() {
    return this.greetingCard
  }

  get weightString() {
    return this.weight
  }

  set weightString(str: string) {
    if (!str.match(weightRegex)) throw new Error('invalid weight value')
    this.weight = str
  }

  get priorityString() {
    return this.priority.toString()
  }

  set priorityString(str: string) {
    this.priority = parseInt(str) || 0
  }

  get rushString() {
    return RushType[this.rush]
  }
  set rushString(str: string) {
    this.rush = RushType[str as keyof typeof RushType]
  }

  get needsRushCharge() {
    return rushTypesWithCharge.indexOf(this.rush) != -1
  }

  get orderableString() {
    return OrderableType[this.orderable]
  }
  set orderableString(str: string) {
    this.orderable = OrderableType[str as keyof typeof OrderableType]
  }

  static defaultFormatDetails(item: ProductI | Product): FormatDetailsI {
    return {
      formatId: item.formatId || 0,
      descriptionId: item.descriptionId || 0,
      priceListId: item.priceListId || 0,
      details: item.details || '',
    }
  }

  static appendFormatDetails(
    item: Product | ProductI,
    details: FormatDetailsI
  ) {
    if (Array.isArray(item.formats)) {
      item.formats.push(details)
    } else {
      item.formats = [details]
    }
  }

  static removeFormatDetails(item: Product, formatId: number) {
    const idx = item.formats.findIndex((f) => f.formatId === formatId)
    if (idx !== -1) item.formats.splice(idx, 1)
  }

  static isValidRushAmount(item: Product, amount: Currency | null): boolean {
    if (!rushTypesWithCharge.includes(item.rush)) return false
    return amount === null || amount.intValue > 0
  }

  get idenity(): number {
    return this.itemId || 0
  }

  static toJSON(product: Product) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return JSON.stringify(product, (key: string, value: any) => {
      if (key === '') return value
      if (key.startsWith('$')) return undefined
      switch (key) {
        case 'fixedShipping':
        case 'rushAmount':
          return value ? (value as Currency).toString() : ''
        default:
          return value
      }
    })
  }

  /**
   * Transforms item into a properly formatted argument to save to the server
   * @param item
   * @returns
   */
  static toRemoteFormat(item: Product) {
    const result = remoteProductSchema.partial().safeParse(item)
    if (!result.success) throw result.error
    const fixedShipping = item.fixedShipping?.toString() || undefined
    const rushAmount = item.rushAmount?.toString() || undefined
    const minObj = { fixedShipping, rushAmount, ...result.data }
    return minObj
  }

  /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
  toJSON() {
    // can't call stringify on this, so make a copy via destructing
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ...rest } = this
    return JSON.stringify(rest, (key: string, value: any) => {
      if (key === '') return value
      switch (key) {
        case 'fixedShipping':
        case 'rushAmount':
          return value ? (value as Currency).toString() : ''
        default:
          return value
      }
    })
  }
}
export function isProduct(item: unknown): item is Product {
  return (item as Entity).entityType === 'Product'
}

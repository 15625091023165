/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { boot } from 'quasar/wrappers'
import { BASE_URL } from 'src/constants/urlConstants'
import {
  AuthCenter,
  getInstance,
  useAuthCenter,
} from '@nowallied/quasar-app-extension-na-core/src/services/AuthCenter'
import { appLog, serviceLog } from '@nowallied/quasar-app-extension-na-core/src/services/Logging'
import {
  isJSError,
  toAlliedError,
  useErrorService,
} from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import {
  AlliedBaseError,
  AlliedErrorCode,
  isAlliedError,
} from '@nowallied/quasar-app-extension-na-core/src/services/Errors'
import ErrorBoot from '@nowallied/quasar-app-extension-na-core/src/boot/errors'
import {
  ItemFormat,
  parseRemoteUser,
  PriceList,
  PriceListI,
  Product,
  ProductI,
  ProductSearchParams,
  ProductSearchResults,
  RemoteProductResultsI,
  RemoteUserWithAddresses,
  TagCollection,
  TagCollectionI,
  User,
} from '@nowallied/quasar-app-extension-na-core/src/models'
import Qs from 'qs'
import LRU_TTL from 'lru-ttl-cache'
import { useAppInfoStore } from 'src/stores/appState'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { useUserStore } from 'src/stores/userStore'
import { useCartStore } from 'src/stores/cart'
import { setupCache } from 'axios-cache-interceptor'
import { Cart } from 'src/models/Cart'

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const ac: AuthCenter = getInstance()
  if (!ac.loggedIn.value) {
    return config
  }
  const token = ac.lastIdToken
  if (token != null && config.headers != null) {
    config.headers.AlliedAuth = token
  } else {
    // they aren't logged in, so no need to add header
  }
  return config
}

export type FetchParameters = {
  offset?: number
  formatIds?: number[]
  count?: number
  sortField?: string
  sortOrder?: 0 | 1
}

export enum IssueSeverity {
  Info = 'info',
  Warning = 'warning',
  Critical = 'critical',
}

export const defaultFetchParams: FetchParameters = { offset: 0, sortOrder: 1 }

export function applyFetchParameters(params: FetchParameters, searchParams: ProductSearchParams) {
  if (params.count) searchParams.count = params.count
  if (params.formatIds) searchParams.formatIds = params.formatIds
  if (params.offset) searchParams.offset = params.offset
  if (params.sortField) searchParams.sortField = params.sortField
  if (params.sortOrder) searchParams.sortAscending = !!params.sortOrder
}

let authCenter: AuthCenter | undefined

class AppServer {
  private _axios: AxiosInstance
  private _cache: LRU_TTL<string, ProductSearchResults | Product | TagCollection[]>

  constructor() {
    const rawAxios = axios.create({ baseURL: BASE_URL, withCredentials: true })
    this._axios = setupCache(rawAxios, { ttl: 1000 * 120 }) //default to 2 minute ttl
    this._axios.interceptors.request.use(injectToken)
    // checkperiod is how many seconds to wait before clearing cache. when live, probably want in increase
    const ttl = process.env.NODE_ENV === 'production' ? 120 : 30
    const ttlInterval = process.env.NODE_ENV === 'production' ? '180s' : '60s'
    this._cache = new LRU_TTL({
      ttl,
      ttlInterval,
    })
  }

  // get cache() {
  //   return this._cache
  // }

  get axios(): AxiosInstance {
    // should find way to make this private and only usable internally and from the store
    return this._axios
  }

  async loadUserInfo(): Promise<User> {
    try {
      const userStore = useUserStore()
      const { data } = await this._axios.get<RemoteUserWithAddresses>('/account')
      const user = parseRemoteUser(data)
      // set user in userStore
      userStore.user = user
      userStore.addresses = user.addresses || []
      if (user.pictureUrl === undefined && authCenter?.pictureUrl) {
        const config: AxiosRequestConfig = {
          method: 'put',
          url: '/account/pictureUrl',
          data: { pictureUrl: authCenter.pictureUrl },
        }
        try {
          const res = await this._axios.request(config)
          if (res.status === 204) {
            user.pictureUrl = authCenter.pictureUrl
          }
        } catch (e) {
          useErrorService().onWarn(
            new AlliedBaseError(AlliedErrorCode.RestError, `set picture failed: ${e}`)
          )
        }
      }
      return user
    } catch (e) {
      throw toAlliedError(e)
    }
  }

  async performSearch(params: ProductSearchParams) {
    const qstr = params.toQueryString()
    // not sure why the as for the key is required. Key is supposed to work with strings
    const cachedResults = await this._cache.get(qstr)
    if (cachedResults instanceof ProductSearchResults) {
      console.log('using cached search')
      return cachedResults
    }
    const config: AxiosRequestConfig = {
      method: 'post',
      url: '/products',
      data: params.toJSON(),
      headers: { 'Content-Type': 'application/json' },
    }
    const res = await this._axios.request(config)
    const results = new ProductSearchResults(res.data as RemoteProductResultsI)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this._cache.set(params.toQueryString(), results)
    return results
  }

  async getProduct(itemId: number) {
    try {
      const cacheKey = `item=${itemId}`
      const cachedResult = this._cache.get(cacheKey)
      if (cachedResult instanceof Product) return cachedResult
      const res = await this._axios.get(`/product/${itemId}`)
      const prod = new Product(res.data as ProductI)
      this._cache.set(cacheKey, prod)
      return prod
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        // no reason for the warning disabled below.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new AlliedBaseError(AlliedErrorCode.NotFound, `product number ${itemId} not found`)
      }
      throw toAlliedError(e)
    }
  }

  async priceListById(priceListId: number) {
    try {
      const res = await this._axios.get(`/pricelist/${priceListId}`)
      return new PriceList(res.data as PriceListI)
    } catch (e) {
      useErrorService().onError(toAlliedError(e))
    }
    return undefined
  }

  async collectionsForFormat(formatId: number): Promise<TagCollection[]> {
    try {
      const cacheKey = `formatId=${formatId}`
      const cachedResponse = this._cache.get(cacheKey)
      if (
        Array.isArray(cachedResponse) &&
        cachedResponse.length > 0 &&
        cachedResponse[0] instanceof TagCollection
      )
        return cachedResponse as TagCollection[]
      const response = await this._axios.get(`/format/${formatId}/collections`)
      const rawCollections = response.data as TagCollectionI[]
      const tc = rawCollections.map((c) => new TagCollection(c))
      this._cache.set(cacheKey, tc)
      return tc
    } catch (e) {
      if (isAlliedError(e)) {
        useErrorService().onError(e)
      }
    }
    return []
  }

  async productsForFormat(
    format: ItemFormat,
    options: FetchParameters = defaultFetchParams
  ): Promise<ProductSearchResults> {
    try {
      const cacheKey = Qs.stringify({ format: format.formatId, ...options }, { indices: false })
      const cachedResults = this._cache.get(cacheKey)
      if (cachedResults instanceof ProductSearchResults) return cachedResults
      const response = await this._axios.get('/product', {
        params: { formatId: format.formatId, ...options },
        paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
      })
      const results = new ProductSearchResults(response.data as RemoteProductResultsI)
      this._cache.set(cacheKey, results)
      return results
    } catch (e) {
      if (isAlliedError(e)) {
        useErrorService().onError(e)
      }
    }
    return new ProductSearchResults()
  }

  async productsForCollection(
    collectionId: number,
    options: FetchParameters = defaultFetchParams
  ): Promise<ProductSearchResults> {
    try {
      const { formatIds, ...rest } = options
      const params =
        formatIds && formatIds[0] > 0 ? { formatId: formatIds[0], ...rest } : { ...rest }
      const cacheKey = Qs.stringify(params, { indices: false })
      const cachedResult = this._cache.get(cacheKey)
      if (cachedResult instanceof ProductSearchResults) return cachedResult
      const response = await this._axios.get(`/collection/${collectionId}`, {
        params,
        paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
      })
      const result = new ProductSearchResults(response.data as RemoteProductResultsI)
      this._cache.set(cacheKey, result)
      return result
    } catch (e) {
      if (isAlliedError(e)) {
        useErrorService().onError(e)
      }
    }
    return new ProductSearchResults()
  }

  async sendVerificationEmail() {
    await this._axios.post('/account/sendVerifyEmail', {})
  }

  async sendPasswordResetEmail() {
    await this._axios.post('/account/resetPassword', {})
  }

  async sendContactData(type: 'wholesale' | 'contact', data: Map<string, string>) {
    const jsonData = Object.fromEntries(data)
    await this._axios.post(`feedback/${type}`, jsonData)
  }

  async reportIssueToAdmin(issue: string, severity: IssueSeverity = IssueSeverity.Info) {
    console.log(`reporting issue: ${issue} with severity ${severity}`)
    try {
      await this._axios.post('/reportIssue', { issue, severity })
    } catch (e) {
      console.log(`error reporting issue: ${e}`)
    }
  }

  async logout(destination: string | undefined = undefined) {
    await this._axios.put('/logout', {})
    document.cookie = 'ct=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    useCartStore().cart = new Cart(undefined)
    if (authCenter) {
      const dest = destination ? destination : document.location.origin
      await authCenter.logout({ returnTo: dest })
    }
  }
}

declare global {
  interface Window {
    alliedAppServer?: AppServer
  }
}

export default boot(async ({ app, router, urlPath, redirect, publicPath }) => {
  // not loading properly by plugin installation. do manually
  try {
    await ErrorBoot({
      app,
      router,
      urlPath,
      redirect,
      publicPath,
    })

    useErrorService().installHandler((kind, error) => {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 403) {
          // should now never happen because auth is checked before making a call requiring auth
          return
        }
      }
      if (typeof error === 'string') {
        serviceLog.info(error)
      } else if (isJSError(error)) {
        if (error.message.indexOf('ResizeObserver') == -1) {
          serviceLog.warn(error.message)
        }
      } else if (error !== undefined) {
        serviceLog.info(`need code to handle ${kind}: ${JSON.stringify(error)}`)
      }
    })
  } catch (e) {
    console.log(`error installing error handling: ${e}`)
  }

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia)

  const theAppServer = new AppServer()
  window.alliedAppServer = theAppServer

  const redirectCallback = async () => {
    const appState = useAppInfoStore()
    if (appState.loginDest && window.location.search.includes('code=')) {
      const dest = appState.loginDest
      appState.loginDest = undefined
      await router.replace(dest)
    }
  }
  type AuthInfo = {
    domain: string
    clientId: string
  }
  const tmpAxios = axios.create({ baseURL: BASE_URL, withCredentials: true })
  const authInfo = (await tmpAxios.get<AuthInfo>('/setup')).data
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ac: AuthCenter = await useAuthCenter(redirectCallback, {
    redirectUri: '/onboard',
    domain: authInfo.domain,
    clientId: authInfo.clientId,
  })
  authCenter = ac
  // appLog.info(`logged in: ${ac.loggedIn.value ? 'true' : 'false'}`)
  if (ac.loggedIn.value) {
    const siteInfoStore = useSiteInfoStore()
    await siteInfoStore.loadSiteData()
    if (!siteInfoStore.user) {
      // need to load user
      try {
        const user = await theAppServer.loadUserInfo()
        siteInfoStore.user = user
      } catch (e) {
        appLog.warn('failed to load user after login', e)
        return
      }
      await useCartStore().fetchCart()
    }
  }
})

export const useAppServer = () => window.alliedAppServer as AppServer

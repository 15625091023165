import { z } from 'zod'

export const payDetailsSchema = z.object({
  cardUsed: z.string().nullable().optional(),
  sqPaymentId: z.string().nullable().optional(),
  sqVersionToken: z.string().nullable().optional(),
  amountAuthorized: z.number().nullable().optional(),
})

export type PaymentDetailsI = z.infer<typeof payDetailsSchema>

export const checkoutInfoSchema = z.object({
  addressId: z.number().nonnegative(),
  addressDescription: z.string().optional(),
  shipmethodId: z.number().nonnegative(),
  orderIdentifier: z.string().min(6),
  paymentInfo: z.string().optional(),
  paymentDesc: z.string().optional(),
  shipInfo: z.string(),
  shipDetails: z.string(),
  taxCost: z.string(),
  shipCost: z.string(),
  subTotal: z.string(),
  total: z.string(),
  paymentValid: z.boolean().default(false),
  notes: z.string(),
  poNumber: z.string().optional(),
  paymentEngine: z.string().default('payByCard'),
  boxId: z.number().optional(),
})

export type CheckoutInfoI = z.infer<typeof checkoutInfoSchema>

export class CheckoutInfo implements CheckoutInfoI {
  addressId = 0
  shipmethodId = 0
  orderIdentifier = ''
  addressDescription?: string
  paymentInfo?: string
  paymentDesc?: string
  shipInfo = ''
  shipDetails = ''
  taxCost = '0.00'
  shipCost = '0.00'
  subTotal = '0.00'
  total = '0.00'
  paymentValid = false
  notes = ''
  poNumber?: string
  paymentEngine = 'payByCard'
  paymentDetails: PaymentDetailsI = {}
  boxId?: number

  constructor(data?: CheckoutInfoI) {
    Object.assign(this, data)
    this.paymentDetails = payDetailsSchema.parse(JSON.parse(this.paymentInfo || '{}'))
  }

  updateTo(info: CheckoutInfoI) {
    this.notes = info.notes
    this.paymentInfo = info.paymentInfo
    this.paymentDetails = payDetailsSchema.parse(JSON.parse(this.paymentInfo || '{}'))
    this.poNumber = info.poNumber
    this.paymentEngine = info.paymentEngine
    this.boxId = info.boxId
  }
}

import { z } from 'zod'
import { Entity } from './Entity'
import { UserAddressI, UserAddress, userAddressSchema } from './UserAddress'

export const userSchema = z.object({
  userId: z.number().nonnegative(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  businessName: z.string().optional(),
  email: z.string().email(),
  mobilePhone: z.string().optional(),
  pictureUrl: z.string().optional(),
  uniqueId: z.string().min(1),
  defaultAddressId: z.number().nonnegative(),
  addrresses: userAddressSchema.array().optional(),
  wholesale: z.boolean().default(false),
  active: z.boolean().default(true),
  textOrderStatus: z.boolean().default(false),
  emailMarketing: z.boolean().default(false),
  defaultPayment: z.string().optional(),
  emailVerified: z.boolean().default(false),
  onboardingComplete: z.boolean().default(false),
  payByCard: z.boolean().default(true),
  payByNetCard: z.boolean().default(false),
  payByNetInvoice: z.boolean().default(false),
  netCardDays: z.number().positive().default(30),
  netInvoiceDays: z.number().positive().default(30),
  billingFirstName: z.string().optional(),
  billingLastName: z.string().optional(),
  billingAddressId: z.number().optional(),
  lastmodified: z.string().optional(),
  datecreated: z.string().optional(),
  hasPassword: z.boolean().default(false),
})

export const adminUserSchema = userSchema.extend({
  siteAdmin: z.boolean(),
  securityRoles: z.string().array(),
  authUserId: z.string(),
  ghostUserId: z.number().optional(),
  ghostedUserId: z.number().optional(),
})

// zod is making everything optional, have open report on github discussion
// export type UserI = z.infer<typeof userSchema>
export type UserI = {
  userId: number
  firstName: string
  lastName: string
  businessName?: string
  email: string
  mobilePhone?: string
  pictureUrl?: string
  uniqueId: string
  defaultAddressId: number
  wholesale: boolean
  addresses?: UserAddressI[]
  active: boolean
  textOrderStatus: boolean
  emailMarketing: boolean
  defaultPayment?: string
  emailVerified: boolean
  onboardingComplete: boolean
  payByCard: boolean
  payByNetCard: boolean
  payByNetInvoice: boolean
  netCardDays: number
  netInvoiceDays: number
  billingFirstName?: string
  billingLastName?: string
  billingAddressId?: number
  lastmodified?: string
  datecreated?: string
  hasPassword: boolean
}

export class User implements Entity {
  readonly entityType = 'User'

  userId = 0
  firstName: string
  lastName: string
  businessName?: string
  email: string
  mobilePhone?: string
  pictureUrl?: string
  uniqueId: string
  defaultAddressId = 0
  wholesale = false
  addresses?: UserAddress[]
  active = true
  emailMarketing = false
  textOrderStatus = false
  defaultPayment?: string
  emailVerified = false
  onboardingComplete = false
  payByCard = true
  payByNetCard = false
  payByNetInvoice = false
  netCardDays = 30
  netInvoiceDays = 30
  billingAddressId?: number | undefined
  billingFirstName?: string | undefined
  billingLastName?: string | undefined
  lastModified: Date
  dateCreated: Date
  hasPassword = false

  constructor(data: UserI) {
    this.userId = data.userId
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.businessName = data.businessName
    this.email = data.email
    this.mobilePhone = data.mobilePhone
    this.pictureUrl = data.pictureUrl
    this.uniqueId = data.uniqueId
    this.defaultAddressId = data.defaultAddressId
    this.wholesale = data.wholesale
    this.active = data.active
    this.emailMarketing = data.emailMarketing
    this.textOrderStatus = data.textOrderStatus
    this.defaultPayment = data.defaultPayment
    this.emailVerified = data.emailVerified
    this.onboardingComplete = data.onboardingComplete
    this.payByCard = data.payByCard
    this.payByNetCard = data.payByNetCard
    this.payByNetInvoice = data.payByNetInvoice
    this.netCardDays = data.netCardDays
    this.netInvoiceDays = data.netInvoiceDays
    this.billingFirstName = data.billingFirstName
    this.billingLastName = data.billingLastName
    this.billingAddressId = data.billingAddressId
    this.hasPassword = data.hasPassword
    if (typeof data.lastmodified === 'string') this.lastModified = new Date(data.lastmodified)
    else this.lastModified = data.lastmodified || new Date()
    if (typeof data.datecreated === 'string') this.dateCreated = new Date(data.datecreated)
    else this.dateCreated = data.datecreated || new Date()
    if (data.addresses) {
      this.addresses = data.addresses.map((a) => new UserAddress(a))
    }
  }

  /// eventually can be made i8n aware
  get fullName() {
    return `${this.firstName} ${this.lastName || ''}`.trim()
  }

  get paymentMethodCount() {
    let count = 0
    if (this.payByCard) count++
    if (this.payByNetCard) count++
    if (this.payByNetInvoice) count++
    return count
  }

  toJSON(): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { addresses, ...data } = this
    return JSON.stringify(data)
  }

  get idenity(): number {
    return this.userId
  }
}

export type AdminUserI = z.infer<typeof adminUserSchema>

export class AdminUser extends User {
  siteAdmin = false
  securityRoles: string[] = []
  authUserId: string
  ghostUserId?: number
  ghostedUserId?: number

  constructor(data: AdminUserI) {
    super(data)
    this.siteAdmin = data.siteAdmin
    this.securityRoles = data.securityRoles
    this.authUserId = data.authUserId
    this.ghostUserId = data.ghostUserId
    this.ghostedUserId = data.ghostedUserId
  }

  updateTo(user: AdminUserI) {
    this.active = user.active
    this.wholesale = user.wholesale
    this.siteAdmin = user.siteAdmin
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.payByCard = user.payByCard
    this.payByNetCard = user.payByNetCard
    this.payByNetInvoice = user.payByNetInvoice
    this.netCardDays = user.netCardDays
    this.netInvoiceDays = user.netInvoiceDays
    this.ghostUserId = user.ghostUserId
    this.ghostedUserId = user.ghostedUserId
  }
}

export interface RemoteUserWithAddresses {
  user: UserI
  addresses: UserAddressI[]
}

export function parseRemoteUser(data: RemoteUserWithAddresses) {
  return new User({ addresses: data.addresses, ...data.user })
}

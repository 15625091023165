import { LogLevelsEnum, SaninnLogger } from '@saninn/logger'

export const serviceLog = new SaninnLogger({ logLevel: LogLevelsEnum.INFO })

/// general logging
export const appLog = serviceLog
/// for remote service type issues
// export const serviceLog = new Category('service', appLog);
/// for authentication type logging
export const authLog = serviceLog

import { z } from 'zod'
import { Entity } from './Entity'

export const userAddressSchema = z.object({
  addressId: z.number().nonnegative(),
  userId: z.number().nonnegative(),
  nickname: z.string().min(2),
  fullName: z.string().min(1),
  company: z.string().nullish(),
  address1: z.string().min(3),
  address2: z.string().nullish(),
  city: z.string().min(2),
  state: z.string().length(2),
  zip: z.string().regex(/\d{5}/),
  phone: z
    .string({ required_error: 'For delivery purposes only' })
    .regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
  description: z.string().nullish(),
})

// export type UserAddressI = z.infer<typeof userAddressSchema>
export type UserAddressI = {
  addressId: number
  userId: number
  nickname: string
  fullName: string
  company?: string
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
  phone: string
  description: string
}

export const emptyUserAddress: UserAddressI = {
  addressId: 0,
  userId: 0,
  nickname: '',
  fullName: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  description: '',
}

export class UserAddress implements UserAddressI, Entity {
  readonly entityType = 'UserAddress'

  addressId = 0
  userId = 0
  nickname = ''
  fullName = ''
  company = ''
  address1 = ''
  address2?: string
  city = ''
  state = ''
  zip = ''
  phone = ''
  description = ''

  constructor(data: UserAddressI) {
    Object.assign(this, data)
  }

  get idenity(): number {
    return this.addressId
  }

  get descriptionWithPhone() {
    if (this.phone.length < 3) return this.description
    return `${this.description}<br>\n${this.phone}`
  }
}

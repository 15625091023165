import { defineStore } from 'pinia'
import { CreateMutable } from '@nowallied/quasar-app-extension-na-core/src/util/types'
import { ProductSearchParams } from '@nowallied/quasar-app-extension-na-core/src/models'
import { QPagination } from 'na-core/src/quasar/QuasarTypes'

const itemsPerPageOptions = [10, 20, 50, 100]

interface IAppInfoState {
  formatId: number
  readonly itemsPerPage: number
  cartPagination: QPagination
  cartMode: 'list' | 'rich'
  loginDest: string | undefined
  searchParams: ProductSearchParams
}

type UnlockedAppInfoState = CreateMutable<IAppInfoState>

export const useAppInfoStore = defineStore({
  id: 'AppInfoStore',
  state: (): IAppInfoState => ({
    formatId: 0,
    itemsPerPage: 10,
    cartPagination: { rowsPerPage: 20, page: 1 },
    cartMode: 'rich',
    loginDest: undefined,
    searchParams: new ProductSearchParams(),
  }),

  getters: {
    itemsPerPageOptions: () => itemsPerPageOptions,
  },

  actions: {
    setItemsPerPage(count: number) {
      if (itemsPerPageOptions.indexOf(count) === -1) {
        throw new Error('invalid itemsPerPage value')
      }
      ;(this as UnlockedAppInfoState).itemsPerPage = count
    },
  },

  persist: {
    paths: ['itemsPerPage', 'loginDest', 'cartItemsPerPage', 'cartMode'],
  },
})

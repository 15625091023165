<template>
  <q-img
    fit="scale-down"
    class="q-my-sm"
    :src="imageTag.src"
    :srcset="imageTag.srcset"
    :img-class="classes"
    :width="imageTag.width + 'px'"
    :height="imageTag.height + 'px'"
  />
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import { ItemFormat, Product } from '@nowallied/quasar-app-extension-na-core/src/models'
import { ImageType, imageTagForProduct } from 'src/utils/ImageUtils'


const props = defineProps({
  product: { type: Object as PropType<Product>, required: true, },
  type: { type: String, required: true, default: ImageType.Listing, },
  format: { type: Object as PropType<ItemFormat>, required: true, },
  details: { type: Boolean, default: false, },
})

const imageTag = computed(() => imageTagForProduct(props.product, props.type as ImageType))

// const classes = computed(() => `${props.details ? 'prod-details-image' : ''} ${imageTag.value.cssClass}`)
const classes = computed(() => props.details ? 'prod-details-image' : '')
</script>

import { ProductI, Product } from './Product'
import Qs from 'qs'
// import { setArbitraryPropertyTS } from "src/util/types"

export interface ProductSearchParamsI {
  name: string
  itemIds: number[]
  tagIds: number[]
  formatIds: number[]
  priority?: number
  priorityOperator?: string
  hasImage?: boolean
  offset: number
  count: number
  sortField: string
  sortAscending: boolean
  random: boolean
  orderable: boolean | undefined
}

export const emptySearchParams = {
  name: '',
  itemIds: [],
  tagIds: [],
  formatIds: [],
  priority: undefined,
  priorityOperator: '>',
  hasImage: undefined,
  offset: 0,
  count: 10,
  sortField: '',
  sortAscending: true,
  random: false,
  orderable: true,
} as ProductSearchParamsI

export class ProductSearchParams implements ProductSearchParamsI {
  name = ''
  itemIds: number[] = []
  tagIds: number[] = []
  formatIds: number[] = []
  priority?: number
  priorityOperator?: '>'
  hasImage?: boolean
  offset = 0
  count = 10
  sortField = ''
  sortAscending = true
  querySearchString = ''
  random = false
  orderable: boolean | undefined = true

  constructor(data?: ProductSearchParamsI) {
    if (data === undefined) Object.assign(this, emptySearchParams)
    else Object.assign(this, data)
  }

  clear() {
    Object.assign(this, emptySearchParams)
  }

  /** true if a search parameter is set (ignoring options) */
  get hasParameter() {
    return (
      this.name.length > 0 ||
      this.itemIds.length > 0 ||
      this.tagIds.length > 0 ||
      this.formatIds.length > 0
    )
  }

  toQueryParams() {
    const params: Record<string, unknown> = {}
    if (this.name.length > 0) params.q = this.name
    if (this.itemIds.length > 0) params.itemId = [...this.itemIds]
    if (this.formatIds.length > 0) params.formatId = [...this.formatIds]
    if (this.tagIds.length > 0) params.tagIds = [...this.tagIds]
    if (this.offset > 0) params.offset = this.offset
    if (this.hasImage) params.hasImage = true
    params.count = this.count
    return params
  }

  toRouteQueryParams() {
    const params: Record<string, unknown> = {}
    if (this.name.length > 0) params.q = this.name
    if (this.itemIds.length > 0) params.itemId = [...this.itemIds]
    if (this.formatIds.length > 0) params.formatId = [...this.formatIds]
    if (this.tagIds.length > 0) params.tagIds = [...this.tagIds]
    return params as Record<string, string>
  }

  toRouteQueryString() {
    return Qs.stringify(this.toRouteQueryParams(), { indices: false })
  }

  toQueryString() {
    return Qs.stringify(this.toQueryParams(), { indices: false })
  }

  /** only parses name and itemIds for now */
  parseQueryString(qstr: string, empty = false) {
    if (qstr.startsWith('?')) qstr = qstr.substring(1)
    if (empty) this.clear()
    const raw = Qs.parse(qstr)
    if (raw.q) {
      if (typeof raw.q === 'string') this.name = raw.q
      else if (typeof raw.q === 'object')
        this.name = Object.values(raw.q).join(' ')
    } else if (raw.name) {
      if (typeof raw.name === 'string') this.name = raw.name
      else if (typeof raw.name === 'object')
        this.name = Object.values(raw.name).join(' ')
    }
    if (this.name) {
      // this.name is now the search term. extract any 4-5 digit numbers as item ids
      const idRegex = /(\d{4,5} *)/g
      const smatch = this.name.match(idRegex)
      if (smatch && smatch.length > 0) {
        const itemIds = [...smatch]
        this.itemIds = itemIds.map((x) => Number(x))
        this.name = this.name.replace(idRegex, '')
      }
    }
    if (raw.itemId) {
      if (typeof raw.itemId === 'string') {
        this.itemIds = [parseInt(raw.itemId, 10)]
      } else if (typeof raw.itemId === 'object') {
        this.itemIds = Object.values(raw.itemId).map((e) =>
          parseInt(e as string, 10)
        )
      }
    }
    if (raw.formatId) {
      if (typeof raw.formatId === 'string') {
        this.formatIds = [parseInt(raw.formatId, 10)]
      } else if (typeof raw.formatId === 'object') {
        this.formatIds = Object.values(raw.formatId).map((e) =>
          parseInt(e as string, 10)
        )
      }
    }
  }

  /**
   * A synthesized query string for the user to view that includes name & itemIds
   *
   * @memberof ProductSearchParams
   */
  get queryString() {
    // let qstring = this.name
    // if (this.itemIds.length > 0) qstring += ' ' + this.itemIds.join(' ')
    // const params: Record<string, unknown> = {
    //   q: qstring,
    // }
    // return decodeURIComponent(Qs.stringify(params, { indices: false }).replace(/^q=/, ''))
    return this.querySearchString.replace(/^([?]?)q=/, '')
  }

  set queryString(str: string) {
    if (!str) {
      this.name = ''
      this.itemIds = []
      this.querySearchString = ''
      return
    }
    if (!str.startsWith('q=')) str = 'q=' + str
    this.querySearchString = str.replace(/^q=/, '')
    this.name = ''
    this.itemIds = []
    this.parseQueryString(str)
  }

  setFormats(formats: number[]) {
    this.formatIds = formats
  }

  addFormatId(formatId: number) {
    if (this.formatIds.indexOf(formatId) === -1) this.formatIds.push(formatId)
  }

  /** Removes the formatId and returns if the formatId was present and removed */
  removeFormatId(formatId: number) {
    const idx = this.formatIds.indexOf(formatId)
    if (idx === -1) return false
    this.formatIds.splice(idx, 1)
    return true
  }

  toJSON() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { querySearchString, ...rest } = this
    return JSON.stringify(rest)
  }

  updateTo(data: ProductSearchParamsI) {
    Object.assign(this, data)
  }

  reset() {
    Object.assign(this, emptySearchParams)
  }

  get isEmpty() {
    return (
      this.name.length < 1 &&
      this.tagIds.length < 1 &&
      this.itemIds.length < 1 &&
      this.formatIds.length < 1 &&
      this.hasImage === null &&
      this.offset === 0 &&
      this.count === 10 &&
      this.sortField.length < 1 &&
      this.sortAscending === true
    )
  }

  get hasImageStr() {
    if (this.hasImage == null) return 'any'
    return this.hasImage ? 'true' : 'false'
  }

  set hasImageStr(str: string) {
    if (str == null || str === 'any') this.hasImage = undefined
    else this.hasImage = str === 'true'
  }

  get orderableStr() {
    if (this.orderable == null) return 'any'
    return this.orderable ? 'true' : 'false'
  }
  set orderableStr(str: string) {
    if (str == null || str === 'any') this.orderable = undefined
    else this.orderable = str === 'true'
  }

  setHasImageString(str: string) {
    this.hasImageStr = str
  }

  get itemIdStr() {
    if (this.itemIds.length === 0) return ''
    return this.itemIds.join(' ')
  }

  set itemIdStr(str: string) {
    if (!str) {
      this.itemIds = []
      return
    }
    const modstr = str.replace(/[^0-9 ]+/, '')
    if (modstr.length < 1) this.itemIds = []
    else this.itemIds = modstr.split(/\D/).map(Number)
  }
}

export interface RemoteProductResultsI {
  totalRowsMatched: number
  results: ProductI[]
}

export interface ProductSearchResultsI {
  totalRowsMatched: number
  items: ProductI[]
}

export class ProductSearchResults {
  totalRowsMatched = 0
  items: Product[] = []

  constructor(data: RemoteProductResultsI | undefined = undefined) {
    if (data !== undefined) {
      this.totalRowsMatched = data.totalRowsMatched
      try {
        this.items = data.results.map((p) => new Product(p))
      } catch (err: unknown) {
        console.log('got error deserializing result')
      }
    }
  }

  update(from: ProductSearchResultsI | ProductSearchResults) {
    this.totalRowsMatched = from.totalRowsMatched
    if (from instanceof ProductSearchResults) this.items = from.items
    else this.items = from.items.map((p) => new Product(p))
  }

  updateProduct(product: Product) {
    const index = this.items.findIndex(
      (value) => value.itemId === product.itemId
    )
    if (index == -1) {
      // added it
      this.items.unshift(product)
    } else {
      this.items[index] = product
    }
  }

  addProduct(product: Product) {
    this.totalRowsMatched = this.items.unshift(product)
  }
}

import { Product, ImageTagI, ItemFormat } from '@nowallied/quasar-app-extension-na-core/src/models'

export enum ImageType {
  Thumb = 'Thumb',
  Full = 'Full',
  Listing = 'Listing',
}

export interface ImageTagInfo {
  itemId: number
  formatId: number
  imageVertical: boolean
}

function sizeForType(
  imageType: ImageType,
  format: ItemFormat,
  vertical: boolean
): { width: number; height: number } {
  switch (imageType) {
    case ImageType.Thumb:
      return { width: 150, height: 150 }
    case ImageType.Listing:
      return { width: 290, height: 290 }
    case ImageType.Full:
      if (vertical) {
        return { width: format.imgWidthV || 290, height: format.imgHeightV || 290 }
      }
      return { width: format.imgWidth || 290, height: format.imgHeight || 290 }
  }
  return { width: 290, height: 290 }
}

const baseCdnUrl = 'https://img.nowallied.com/'

export function imageTagForProduct(product: Product, imageType: ImageType): ImageTagI {
  switch (imageType) {
    case ImageType.Thumb:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return product.thumbTag! || product.imageTag!
    case ImageType.Listing:
      // this is a hack as ItemGroupsItems don't have a listingTag but get passed in here
      if ((product.entityType as string) === 'ItemGroupItem') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return product.imageTag!
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return product.listingTag || product.thumbTag || product.imageTag!
    case ImageType.Full:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return product.imageTag!
  }
}

/// returns the ImageTag to use for the specified parameters
export function imageTagFor(
  product: ImageTagInfo,
  format: ItemFormat,
  imageType: ImageType,
  siteCode: string
): ImageTagI {
  if (format === undefined || product === undefined) {
    return {} as ImageTagI
  }
  const { width, height } = sizeForType(imageType, format, product.imageVertical)
  let start = '',
    end = ''
  if (format.name === 'card') {
    end = `,dpr-DPR,cm-pad_resize/${siteCode}/bg/plainCardV.png`
    if (product.imageVertical) {
      start = `${baseCdnUrl}tr:oi-${siteCode}@@product@@${product.itemId}.jpg,oit-false,ow-1500,ox-202,oy-186:`
      end = `,dpr-DPR,cm-pad_resize/${siteCode}/bg/plainCardV.png`
    } else {
      start = `${baseCdnUrl}tr:oi-${siteCode}@@product@@${product.itemId}.jpg,oit-false,ow-2100,ox-114,oy-96:`
      end = `,dpr-DPR,cm-pad_resize/${siteCode}/bg/plainCardH.png`
    }
  } else if (format.name === 'journal') {
    start = `${baseCdnUrl}tr:w-2400,h-2554,bg-00000000:ox-356,oh-2554.oy-0,oi-@@lbr@@bg@@journal-bg.png:`
    end = `,cm-pad_resize/lbr/product/${product.itemId}.jpg`
  } else if (format.name === 'datebook') {
    start = `${baseCdnUrl}/${siteCode}/product/${product.itemId}.jpg?tr=h-2260,w-1572,cm-pad_resize,fo-right,l-image,i-/${siteCode}/bg/whiterings.png,lfo-left,l-end:`
    end = ',cm-pad_resize'
  } else {
    // default product
    start = `${baseCdnUrl}tr:cm-pad_resize,dpr-DPR,`
    // listings have a border
    if (imageType === ImageType.Listing || imageType === ImageType.Full) {
      start += 'b-1_00000010,'
    }
    end = `,bg-00000000/${siteCode}/product/${product.itemId}.jpg`
  }
  let src = `${start}w-${width},h-${height}${end}`
  const srcset = `${src.replace(/DPR/g, '1')} 1x, ${src.replace(/DPR/g, '2')} 2x, ${src.replace(
    /DPR/g,
    '3'
  )} 3x`
  src = src.replace(/DPR/g, '1')
  return {
    cssClass: '',
    src,
    srcset,
    width,
    height,
  }
}

import { Entity } from './Entity'
import { ImageTagI, imageTagSchema } from './Product'
import { z } from 'zod'

const RawTagCollectionSchema = z.object({
  collectionId: z.number().int().positive(),
  siteId: z.number().int().positive(),
  name: z.string(),
  pathName: z.string(),
  parentId: z.number().int().positive().optional().nullable(),
  childIds: z.number().int().positive().array(),
  tagIds: z.number().int().positive().array(),
  hasImage: z.boolean(),
  defaultItemId: z.number().int().optional().nullable(),
  imageTag: imageTagSchema.optional(),
})

type RawTagCollectionI = z.infer<typeof RawTagCollectionSchema> & {
  children: TagCollectionI[]
}

export const TagCollectionSchema: z.ZodType<RawTagCollectionI> =
  RawTagCollectionSchema.extend({
    children: z.lazy(() => TagCollectionSchema.array()),
  })

export type TagCollectionI = z.infer<typeof TagCollectionSchema>

export function isTagCollection(item: unknown): item is TagCollectionI {
  return (item as Entity).entityType === 'TagCollection'
}

export const emptyTagCollection = {
  collectionId: 0,
  siteId: 0,
  name: 'untitled',
  pathName: 'untitled',
  childIds: [],
  tagIds: [],
  children: [],
  hasImage: false,
  defaultItemId: undefined,
} as TagCollectionI

export class TagCollection implements TagCollectionI, Entity {
  readonly entityType = 'TagCollection'
  collectionId: number
  siteId: number
  name: string
  pathName: string
  parentId?: number | undefined
  childIds: number[]
  tagIds: number[]
  children: TagCollection[]
  hasImage: boolean
  defaultItemId: number | undefined
  imageTag?: ImageTagI

  constructor(data: TagCollectionI) {
    this.collectionId = data.collectionId
    this.siteId = data.siteId
    this.name = data.name
    this.pathName = data.pathName
    this.parentId = data.parentId
    this.childIds = data.childIds
    this.tagIds = data.tagIds
    this.children = data.children
      ? data.children
          .map((kid) => new TagCollection(kid))
          .sort((a, b) => a.name.localeCompare(b.name))
      : []
    this.hasImage = data.hasImage
    this.defaultItemId = data.defaultItemId
    this.imageTag = data.imageTag
  }

  toJSON(): string {
    // can't call stringify on this, so make a copy via destructing
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...rest } = this
    return JSON.stringify(rest)
  }

  get idenity(): number {
    return this.collectionId
  }

  get isLeaf() {
    return this.children.length < 1
  }

  get leafTypeChosen() {
    return this.childIds.length > 0 || this.tagIds.length > 0
  }

  isTopLevelCollection(): boolean {
    return this.parentId !== undefined
  }

  updateFrom(other: TagCollectionI) {
    this.name = other.name
    this.parentId = other.parentId
    this.childIds = other.childIds
    this.tagIds = other.tagIds
    this.hasImage = other.hasImage
    this.defaultItemId = other.defaultItemId
    this.imageTag = other.imageTag
    // children need to be copied instead of referenced
  }

  /// throws a ZodError if not valid
  validate() {
    return TagCollectionSchema.parse(this) !== undefined
  }

  isValid() {
    return TagCollectionSchema.safeParse(this).success
  }
}

/** recursively searches for a collection by id */
export function findCollection(
  collectionId: number,
  collections: TagCollection[]
): TagCollection | undefined {
  for (const tag of collections) {
    if (tag.collectionId === collectionId) return tag
    const childMatch = findCollection(collectionId, tag.children)
    if (childMatch !== undefined) return childMatch
  }
  return undefined
}

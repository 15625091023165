import { Entity } from './Entity'

export interface ItemFormatI {
  name: string
  siteId: number
  displayName: string
  cssClass: string | undefined
  thumbClass: string | undefined
  cssClassV: string | undefined
  thumbClassV: string | undefined
  imgHeight: number | undefined
  imgWidth: number | undefined
  imgHeightV: number | undefined
  imgWidthV: number | undefined
  thumbHeight: number | undefined
  thumbWidth: number | undefined
  thumbHeightV: number | undefined
  thumbWidthV: number | undefined
  minQtyForCustom: number
  userVisible: boolean
  shipHeight: string
  shipWidth: string
  shipDepth: string
  shipQuantity: number
}

export class ItemFormat implements Entity, ItemFormatI {
  readonly entityType = 'ItemFormat'
  formatId = 0
  name = ''
  siteId = 0
  displayName = ''
  cssClass: string | undefined
  thumbClass: string | undefined
  cssClassV: string | undefined
  thumbClassV: string | undefined
  imgHeight: number | undefined
  imgWidth: number | undefined
  imgHeightV: number | undefined
  imgWidthV: number | undefined
  thumbHeight: number | undefined
  thumbWidth: number | undefined
  thumbHeightV: number | undefined
  thumbWidthV: number | undefined
  minQtyForCustom = 1
  userVisible = true
  shipHeight: string
  shipWidth: string
  shipDepth: string
  shipQuantity = 1

  constructor(data: ItemFormatI | undefined = undefined) {
    this.shipDepth = data?.shipDepth || '1'
    this.shipWidth = data?.shipWidth || '1'
    this.shipHeight = data?.shipHeight || '1'
    if (data !== undefined) {
      Object.assign(this, data)
    }
  }

  get idenity() {
    return this.formatId
  }

  imageWidth(vertical: boolean) {
    return vertical ? this.imgWidthV : this.imgWidth
  }

  imageHeight(vertical: boolean) {
    return vertical ? this.imgHeightV : this.imgHeight
  }

  toJSON() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { entityType, ...rest } = this
    return JSON.stringify(rest)
  }

  updateFrom(other: ItemFormatI) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    const { entityType, ...rest } = other as any
    Object.assign(this, rest)
  }
}

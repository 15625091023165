import { defineStore } from 'pinia'
import {
  Site,
  User,
  SiteTag,
  SiteTagI,
  ItemFormat,
  ItemFormatI,
  TagCollection,
  TagCollectionI,
  ItemGroupI,
  ItemGroup,
  PriceListI,
  PriceList,
  Stockist,
} from '@nowallied/quasar-app-extension-na-core/src/models'
import { useAppServer } from 'src/boot/AppServer'
import {
  isAppError,
  useErrorService,
} from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import { useUserStore } from './userStore'

type SiteInfoI = {
  site: Site
  user?: User
  tags: SiteTagI[]
  formats: ItemFormatI[]
  tagCollections: TagCollectionI[]
  itemGroups: ItemGroupI[]
  priceLists: PriceListI[]
  squareJsLibUrl: string
  initialCartCount: number
}

interface ISiteInfoState {
  loading: boolean
  site: Site
  user?: User
  tags: SiteTag[]
  formats: ItemFormat[]
  tagCollections: TagCollection[]
  itemGroups: ItemGroup[]
  priceLists: PriceList[]
  collectionsByPath: Map<string, TagCollection>
  squareJsLibUrl: string
  initialCartCount: number
}

let loadPromise: Promise<void> | null = null

export const useSiteInfoStore = defineStore({
  id: 'SiteInfoStore',
  state: (): ISiteInfoState => ({
    loading: false,
    site: new Site(),
    tags: [],
    formats: [],
    priceLists: [],
    tagCollections: [],
    itemGroups: [],
    collectionsByPath: new Map(),
    squareJsLibUrl: '',
    initialCartCount: 0,
  }),

  getters: {},

  actions: {
    async loadSiteData() {
      if (loadPromise) return loadPromise
      const appServer = useAppServer()
      loadPromise = new Promise((resolve, reject) => {
        this.loading = true
        appServer.axios
          .get('/info')
          .then((response) => {
            const data = response.data as SiteInfoI
            this.site = data.site
            this.formats = data.formats.map((f) => new ItemFormat(f))
            this.priceLists = data.priceLists.map((f) => new PriceList(f))
            this.user = data.user
            this.tags = data.tags.map((t) => new SiteTag(t))
            this.tagCollections = data.tagCollections.map((tc) => new TagCollection(tc))
            this.itemGroups = data.itemGroups.map((g) => new ItemGroup(g, this.formats))
            this.squareJsLibUrl = data.squareJsLibUrl
            this.initialCartCount = data.initialCartCount
            useUserStore().user = this.user
            resolve()
          })
          .catch((err) => {
            if (isAppError(err)) {
              useErrorService().onError(err)
              reject()
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
      return loadPromise
    },

    itemGroupWithName(name: string): ItemGroup | undefined {
      return this.itemGroups.find((ig) => ig.name === name)
    },

    formatWithName(name: string): ItemFormat | undefined {
      return this.formats.find((fmt) => fmt.name === name)
    },

    formatWithNameOrDefault(name: string): ItemFormat {
      return this.formats.find((fmt) => fmt.name === name) || this.formats[0]
    },

    priceListWithId(priceListId: number): PriceList {
      return this.priceLists.find((pl) => pl.priceListId === priceListId) || this.priceLists[0]
    },

    formatWithIdOrDefault(formatId: number): ItemFormat {
      return this.formats.find((fmt) => fmt.formatId === formatId) || this.formats[0]
    },

    formatsWithIds(formatIds: number[]) {
      const fmts = formatIds.map((fid) => this.formats.find((f) => f.formatId === fid))
      return fmts.filter(Boolean) as ItemFormat[] // remove undefineds from bad formatIds
    },

    collectionByPath(path: string): TagCollection | undefined {
      if (this.collectionsByPath.size < 1) {
        const all = new Map<string, TagCollection>()
        const recfun = (tc: TagCollection) => {
          all.set(tc.pathName, tc)
          tc.children.forEach((child) => recfun(child))
        }
        this.tagCollections.forEach((tc) => recfun(tc))
        this.collectionsByPath = all
      }
      return this.collectionsByPath.get(path)
    },

    /** returns matching array collection objects, items after the first being a child
     * i.e. 'states/colorado/aspen' would return the TagCollections [States, Colorado, Aspen]
     * @retrun stack of TagCollection[]
     * @throws Error if invalid path
     */
    collectionStackForPath(paths: string[]): TagCollection[] {
      let curCollection = this.tagCollections.find((tc) => tc.pathName === paths[0].toLowerCase())
      if (curCollection === undefined) return []
      const stack: TagCollection[] = [curCollection]
      for (let i = 1; i < paths.length; i += 1) {
        curCollection = curCollection.children.find(
          (child) => child.pathName === paths[i].toLowerCase()
        )
        if (curCollection === undefined) break
        stack.push(curCollection)
      }
      if (stack.length !== paths.length) throw new Error('invalid collection path')
      return stack
    },

    collectionsByPaths(paths: string[]): TagCollection[] {
      const result: TagCollection[] = []
      paths.forEach((path) => {
        const c = this.collectionByPath(path)
        if (c !== undefined) result.push(c)
      })
      return result
    },

    async stockistsData() {
      const response = await useAppServer().axios.get<StockistDataI>('/stockists')
      const stockistsById = new Map<number, Stockist>()
      response.data.stockists.forEach((s) => {
        const stockist = new Stockist(s)
        stockistsById.set(stockist.stockistId, stockist)
      })
      return {
        stockistsById,
        stateData: response.data.stateData,
      }
    },
  },
})

export type StockistCityData = {
  cityName: string
  stockistIds: number[]
}

export type StockistStateData = {
  stateName: string
  cities: StockistCityData[]
}

export type StockistDataI = {
  stockists: Stockist[]
  stateData: StockistStateData[]
}

export type StockistData = {
  stockistsById: Map<number, Stockist>
  stateData: StockistStateData[]
}

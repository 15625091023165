export interface SavedCardI {
  cardId: string
  brand: string
  expDate: string
  last4: string
  zipCode: string
}

export class SavedCard {
  cardId: string
  brand: string
  expirationDate: string
  last4: string
  zipCode?: string

  constructor(data: SavedCardI) {
    this.cardId = data.cardId
    this.brand = data.brand
    this.expirationDate = data.expDate
    this.last4 = data.last4
    this.zipCode = data.zipCode
  }

  get friendlyBrandName() {
    switch (this.brand) {
      case 'VISA':
        return 'Visa'
      case 'MASTERCARD':
        return 'Mastercard'
      case 'AMERICAN_EXPRESS':
        return 'American Express'
      case 'DISCOVER':
        return 'Discover'
      default:
        return this.brand
    }
  }

  get logoName() {
    switch (this.brand) {
      case 'VISA':
        return 'visa.svg'
      case 'MASTERCARD':
        return 'mastercard.svg'
      case 'AMERICAN_EXPRESS':
        return 'amex.svg'
      case 'DISCOVER':
        return 'discover.svg'
      default:
        return 'generic.svg'
    }
  }

  get fakeNumber() {
    if (this.brand === 'AMERICAN_EXPRESS') {
      return 'XXXX-XXXXXX-X' + this.last4
    }
    return 'XXXX-XXXX-XXXX-' + this.last4
  }
}

<template>
  <div>
    <div class="xgt-sm" style="min-width: 100px; max-width: 400px;">
      <q-select ref="selectItem" outlined v-model="selectedFormats" :options="formats" multiple
        option-label="displayName" dense options-dense label="Formats" stack-label
        hide-bottom-space @popup-hide="onClose" @popup-show="onOpen">
        <template v-slot:selected>
          <div v-if="selectedFormats.length == 0">Any</div>
          <div v-else>
            <q-chip size="md" dense v-for="(format, idx) in selectedFormats" :key="format.formatId" :label="format.displayName" removable @remove="removeSelection(idx)">
            </q-chip>
          </div>
        </template>
      </q-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ItemFormat } from '@nowallied/quasar-app-extension-na-core/src/models'
import { QSelect } from 'quasar'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { PropType, onMounted, ref, watch } from 'vue'

const props = defineProps({
  selection: { type: Object as PropType<ItemFormat[]>, required: true },
})

const formats = ref<ItemFormat[]>([])
const selectedFormats = ref<ItemFormat[]>([])
const selectItem = ref<QSelect | null>(null)
const menuVisible = ref(false)

const emit = defineEmits(['change'])

function onOpen() {
  menuVisible.value = true
}

function onClose() {
  menuVisible.value = false
  emit('change', selectedFormats.value)
}

onMounted(() => {
  formats.value = useSiteInfoStore().formats
  selectedFormats.value = props.selection
  watch(() => props.selection, (newVal) => {
    selectedFormats.value = newVal
  })
})

function removeSelection(idx: number) {
  selectedFormats.value.splice(idx, 1)
  if (!menuVisible.value) {
    emit('change', selectedFormats.value)
  }
}

</script>

/* eslint max-classes-per-file: ["error", 2] */
import { appLog } from '../services/Logging'
import { Entity } from './Entity'
import { z } from 'zod'

export const SiteTagSchema = z.object({
  tagId: z.number().int(),
  siteId: z.number().int().positive(),
  name: z.string(),
  visible: z.boolean().default(false),
  itemCount: z.number().int().gt(-1),
  priority: z.number().int().min(0).max(10),
  defaultItemId: z.number().int().optional().nullable(),
  hasImage: z.boolean().default(false),
})

export type SiteTagI = z.infer<typeof SiteTagSchema>

export const emptyTag: SiteTagI = {
  tagId: 0,
  siteId: 0,
  name: '',
  visible: false,
  itemCount: 0,
  priority: 0,
  hasImage: false,
}

export const sortTagByName = (a: SiteTag, b: SiteTag) =>
  a.name.localeCompare(b.name)

export class SiteTag implements Entity {
  readonly entityType = 'SiteTag'
  tagId: number
  siteId: number
  name: string
  visible: boolean
  itemCount: number
  priority: number
  defaultItemId?: number
  hasImage: boolean
  private saved: SiteTagI

  constructor(srcTag: SiteTagI = emptyTag) {
    this.tagId = srcTag.tagId
    this.siteId = srcTag.siteId
    this.name = srcTag.name
    this.visible = srcTag.visible
    this.itemCount = srcTag.itemCount
    this.priority = srcTag.priority
    this.defaultItemId = srcTag.defaultItemId || undefined
    this.hasImage = srcTag.hasImage
    this.saved = { ...srcTag }
  }

  toJSON(): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { entityType, saved, ...rest } = this
    return JSON.stringify(rest)
  }

  get idenity(): number {
    return this.tagId
  }

  get priorityString() {
    return this.priority.toString()
  }

  set priorityString(str) {
    let ival = parseInt(str, 10)
    if (Number.isNaN(ival)) {
      ival = 0
    }
    this.priority = ival
  }

  isDirty() {
    return !(
      // prettier-ignore
      this.tagId === this.saved.tagId
      && this.name === this.saved.name
      && this.visible === this.saved.visible
      && this.priority === this.saved.priority
      && !!this.defaultItemId === !!this.saved.defaultItemId
    )
  }

  // called to update with data from the server
  updateFrom(data: SiteTagI) {
    try {
      data = SiteTagSchema.parse(data)
    } catch (error) {
      throw new Error(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Invalid Object returned from server: ${error} is invalid`
      )
    }
    this.saved = new SiteTag(data)
    this.revert()
  }

  update() {
    this.saved.name = this.name
    this.saved.visible = this.visible
    this.saved.priority = this.priority
    this.saved.defaultItemId = this.defaultItemId
  }

  revert() {
    this.name = this.saved.name
    this.visible = this.saved.visible
    this.priority = this.saved.priority
    this.defaultItemId = this.saved.defaultItemId
  }

  /// throws a ZodError if not valid
  validate() {
    return SiteTagSchema.parse(this)
  }

  isValid() {
    return this.validate()
  }
}

/** returns array of tagIds for the specified tagNames */
export function idsForTags(tags: SiteTag[], tagNames: string[]): number[] {
  const ids: number[] = []
  if (!Array.isArray(tagNames)) {
    throw Error('tagNames must be an array')
  }
  const names = [...tagNames]
  /* eslint  arrow-body-style: "off" */
  tags.some((aTag) => {
    // iterate through tagNames looking for one that matches aTag
    return tagNames.some((aName) => {
      if (
        aName.localeCompare(aTag.name, undefined, { sensitivity: 'base' }) === 0
      ) {
        names.splice(names.indexOf(aName), 1)
        ids.push(aTag.tagId)
      }
      return ids.length === tagNames.length
    })
  })
  return ids
}

export function tagsForIds(allTags: SiteTag[], tagIds: number[]) {
  const outTags: SiteTag[] = []
  tagIds.forEach((anId) => {
    const theTag = allTags.find((aTag) => aTag.tagId === anId)
    if (theTag) {
      outTags.push(theTag)
    }
  })
  if (outTags.length !== tagIds.length) {
    appLog.warn(`no tag for all ids ${JSON.stringify(tagIds)}`)
  }
  return outTags
}

import { Entity, NamedEntity } from './Entity'
import { ItemFormat } from './ItemFormat'
import { ImageTagI, imageTagSchema } from './Product'
import { z } from 'zod'

export const ItemGroupItemSchema = z.object({
  itemgroupItemId: z.number().int(),
  itemgroupId: z.number().int(),
  itemName: z.string().optional(),
  itemId: z.number().int(),
  formatId: z.number().int(),
  imageVertical: z.boolean(),
  hasImage: z.boolean(),
  priority: z.number().int(),
  imageTag: imageTagSchema,
  thumbTag: imageTagSchema,
})

export type ItemGroupItemI = z.infer<typeof ItemGroupItemSchema>

export class ItemGroupItem implements NamedEntity, ItemGroupItemI {
  readonly entityType = 'ItemGroupItem'
  itemgroupId = 0
  itemgroupItemId = 0
  itemName?: string
  itemId = 0
  formatId = 0
  itemFormat?: ItemFormat
  imageVertical = false
  priority = 0
  hasImage = false
  imageTag: ImageTagI
  thumbTag: ImageTagI

  constructor(data: ItemGroupItemI) {
    this.itemgroupId = data.itemgroupId
    this.itemgroupItemId = data.itemgroupItemId
    this.itemId = data.itemId
    this.priority = data.priority
    this.itemName = data.itemName
    this.formatId = data.formatId
    this.imageVertical = data.imageVertical
    this.hasImage = data.hasImage
    this.imageTag = data.imageTag
    this.thumbTag = data.thumbTag
  }

  get name() {
    return this.itemName || ''
  }

  get idenity() {
    return this.itemgroupItemId
  }

  toJSON() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { entityType, ...rest } = this
    return JSON.stringify(rest)
  }
}

export const ItemGroupSchema = z.object({
  itemgroupId: z.number().int(),
  name: z.string(),
  displayName: z.string(),
  defaultItemId: z.number().int(),
  hasImage: z.boolean().default(false),
  priority: z.number().int().default(0),
  imageTag: imageTagSchema.optional(),
  items: ItemGroupItemSchema.array(),
})

export type ItemGroupI = z.infer<typeof ItemGroupSchema>

export const itemGroupDefaults: ItemGroupI = {
  itemgroupId: 0,
  name: '',
  displayName: '',
  defaultItemId: 0,
  hasImage: false,
  priority: 0,
  items: [],
}

export class ItemGroup implements Entity, ItemGroupI {
  readonly entityType = 'ItemGroup'
  itemgroupId = 0
  name: string
  displayName: string
  defaultItemId = 0
  hasImage = false
  priority = 0
  imageTag?: ImageTagI
  items: ItemGroupItem[] = []

  constructor(data: ItemGroupI, formats: ItemFormat[]) {
    this.itemgroupId = data.itemgroupId
    // these get assigned twice, but otherwise we get errors that they weren't set in the constructor
    this.name = data.name
    this.displayName = data.displayName
    this.updateTo(data)
    this.items.forEach((item) => {
      item.itemFormat = formats.find((f) => f.formatId === item.formatId) || undefined
    })
  }

  updateTo(data: ItemGroupI) {
    this.name = data.name
    this.displayName = data.displayName
    this.defaultItemId = data.defaultItemId
    this.hasImage = data.hasImage
    this.priority = data.priority
    this.imageTag = data.imageTag
    this.items = data.items.map((item) => new ItemGroupItem(item))
  }

  addItem(item: ItemGroupItem) {
    this.items.push(item)
    this.items.sort((a, b) => b.priority - a.priority)
  }

  removeItem(itemId: number) {
    const idx = this.items.findIndex((i) => i.itemgroupItemId === itemId)
    if (idx >= 0) this.items.splice(idx, 1)
  }

  moveItem(fromIndex: number, toIndex: number) {
    this.items.splice(toIndex, 0, this.items.splice(fromIndex, 1)[0])
  }

  get idenity() {
    return this.itemgroupId
  }

  toJSON() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { entityType, items, ...rest } = this
    const sitems = items.map((i) => i.toJSON())
    return JSON.stringify({ ...rest, items: sitems })
  }

  toItemGroupI() {
    const itemIds = this.items.map((i) => i.itemgroupItemId)
    return {
      itemgroupId: this.itemgroupId,
      priority: this.priority,
      name: this.name,
      displayName: this.displayName,
      itemIds,
      defaultItemId: this.defaultItemId,
    }
  }

  isValid() {
    return ItemGroupSchema.safeParse(this).success
  }
}

export function isItemGroup(item: unknown): item is ItemGroup {
  return (
    item != null &&
    (typeof item === 'object' || typeof item === 'function') &&
    (item as Entity).entityType === 'ItemGroup'
  )
}

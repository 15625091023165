import Currency from 'currency.js'
import { Entity } from './Entity'

export enum PriceListType {
  Lot = 'Lot',
  Unit = 'Unit',
  Hundred = 'Hundred',
  Thousand = 'Thousand',
}

export interface PricePointI {
  pricePointId: number
  quantity: number
  price: string
}

export class PricePoint implements Entity {
  readonly entityType = 'PricePoint'
  pricePointId: number
  quantity: number
  price: Currency

  constructor(data: PricePointI) {
    this.pricePointId = data.pricePointId
    this.quantity = data.quantity
    this.price = Currency(data.price)
  }

  toInterface(): PricePointI {
    return {
      pricePointId: this.pricePointId,
      quantity: this.quantity,
      price: this.price.toString(),
    }
  }

  get idenity(): number {
    return this.pricePointId
  }

  toJSON(): string {
    return JSON.stringify({
      pricePointId: this.pricePointId,
      quantity: this.quantity,
      price: this.price.toString(),
    })
  }
}

export interface PriceListI {
  priceListId: number
  name: string
  listType: string
  freeForm: boolean
  wholesaleDiscount: number
  pricePoints: PricePointI[]
}

const defaultPriceList: PriceListI = {
  priceListId: 0,
  name: 'list',
  listType: 'Unit',
  freeForm: false,
  wholesaleDiscount: 0,
  pricePoints: [],
}

export class PriceList implements Entity {
  entityType = 'PriceList'
  priceListId: number
  name: string
  listType: PriceListType
  freeForm: boolean
  wholesaleDiscount: number
  pricePoints: PricePoint[]

  constructor(data: PriceListI = defaultPriceList) {
    this.priceListId = data.priceListId
    this.name = data.name
    this.freeForm = data.freeForm
    this.wholesaleDiscount = data.wholesaleDiscount
    this.pricePoints = data.pricePoints.map((pp) => new PricePoint(pp))
    this.pricePoints.sort((a, b) => a.quantity - b.quantity)
    try {
      this.listType = PriceListType[data.listType as keyof typeof PriceListType]
    } catch (ignored) {
      this.listType = PriceListType.Unit
    }
  }

  updateTo(data: PriceListI) {
    this.name = data.name
    this.wholesaleDiscount = data.wholesaleDiscount
    this.freeForm = data.freeForm
    if (data.pricePoints.length) {
      const pp = data.pricePoints.map((pp) => new PricePoint(pp))
      pp.sort((a, b) => a.quantity - b.quantity)
      this.pricePoints.splice(0, this.pricePoints.length, ...pp)
    }
    try {
      this.listType = PriceListType[data.listType as keyof typeof PriceListType]
    } catch (ignored) {
      this.listType = PriceListType.Unit
    }
  }

  editableClone() {
    const points = this.pricePoints.map((pp) => pp.toInterface())
    return new PriceList({
      name: this.name,
      freeForm: this.freeForm,
      wholesaleDiscount: this.wholesaleDiscount,
      priceListId: this.priceListId,
      listType: this.listType,
      pricePoints: points,
    })
  }

  pricePointForQuantity(qty: number) {
    // const theArray = this.freeForm
    //   ? this.pricePoints.slice().reverse()
    //   : this.pricePoints.slice()
    const res = this.pricePoints
      .slice()
      .reverse()
      .find((pp) => pp.quantity <= qty)
    return res || this.pricePoints[0]
  }

  get listTypeName() {
    return this.listType.toString()
  }
  set listTypeName(str: string) {
    this.listType = PriceListType[str as keyof typeof PriceListType]
  }

  get idenity(): number {
    return this.priceListId
  }

  toJSON(): string {
    return JSON.stringify({
      priceListId: this.priceListId,
      name: this.name,
      freeForm: this.freeForm,
      pricePoints: this.pricePoints.map((pp) => pp.toJSON()),
    })
  }
}

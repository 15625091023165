<template>
  <div class="whimsy-block">
    <div class="whimsy-outer">
      <div class="row whimsy-inner">
        <div class="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-5 whimsy-col">
          <div class="whimsy-title">Bring a dash of whimsy to your daily life.</div>
          <div class="whimsy-text">
                Paper collaged by hand with upcycled magazines. Crafted for a cause.
              </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row items-center justify-evenly q-mt-lg">
    <div class="text-center row">
      <div class="col">
        <div class="tk-sofia-pros text-h4">Featured Collections</div>
      </div>
    </div>
    <item-groups-component v-for="group in groups" :key="group.itemgroupId" :group="group" :thumbnails="false" show-add-to-cart />

    <section class="bg-sand col-12 q-mb-lg overflow-hidden image-left-text-right relative-position">
      <div class="lt-md"><img class="img-fluid" src="https://img.nowallied.com/lbr/static/mother-daughter-about.jpg"></div>
      <div class="container">
        <div class="row items-center">
          <div class="gt-xs col-6 ourstory-img" style="position: static"></div>
          <div class="col-xs-12 col-6 callout-block-wrapper">
            <div class="callout-block">
              <div class="heading-xxs ls-1em text-grey-5">
                About Us
              </div>
              <div class="callout-text tk-ivyjournal q-mb-md">
                Lilybart started as a way to feel inspired alongside life with Cystic Fibrosis.
              </div>
              <div class="callout-button">
                <q-btn no-caps color="black" class="sm-btn-icon" label="Our Story"
                  :icon-right="fasArrowRight" :to="{name:'OurStory'}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
// import { ItemGroup } from '@nowallied/quasar-app-extension-na-core/src/models'
import { onMounted } from 'vue'
import ItemGroupsComponent from 'src/components/ItemGroupsComponent.vue'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { fasArrowRight } from '@quasar/extras/fontawesome-v6'

const groups = useSiteInfoStore().itemGroups

onMounted(() => {
  const icon = document.querySelector('.sm-btn-icon .on-right') as HTMLElement|undefined
  if (icon) icon.style.fontSize = '14px'
})
</script>

<style lang="scss" scoped>
.ourstory-img {
  background-image: url(https://img.nowallied.com/lbr/static/mother-daughter-about.jpg);
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  height: 700px;
  background-size: cover;
  background-position: 70% center;
  background-repeat: no-repeat;
}
.callout-block-wrapper {
  @media (min-width: $breakpoint-sm-min) {
    max-width: 50%;
  }
}
.callout-block {
  @media (max-width: $breakpoint-sm-min) {
    text-align: center;
  }
  @media (min-width: $breakpoint-sm-min) {
    margin-left: 20%;
    margin-right: 20%;
    max-width: 370px;
  }
}
.callout-button {
  margin-top: 36px;
}
// this is not working, but does via javascript
.sm-btn-icon .on-right {
  font-size: 12px !important;
}

.callout-text {
  font-size: 2.25rem;
  @media (min-width: $breakpoint-sm-min) {
    font-size: 2.75rem;
  }
  line-height: 1.2;
  font-weight: 300;
}
.whimsy-block {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-image: url(https://img.nowallied.com/lbr/static/hero-image.jpg);
  @media (max-width: $breakpoint-sm-min) {
    background-position-x: end !important;
  }
}
.whimsy-outer {
  flex-direction: column !important;
  display: flex !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
}
.whimsy-inner {
  min-height: 550px;
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
  line-height: 1.2;
  font-weight: 500;
  @media (min-width: $breakpoint-md-min) {
    margin-left: 5rem !important;
    align-items: center !important;
  }
  @media (max-width: $breakpoint-sm-min) {
    // color: white !important;
    font-weight: 900;
    text-shadow: 2px 2px 6px white;
  }
}
.whimsy-col {
  padding-right: 4rem;
  margin-right: 3rem;
  @media (min-width: $breakpoint-md-min) {
    padding-right: 7rem;
  }
}
.whimsy-title {
  font-size: 2.75rem;
  font-family: ivyjournal;
  margin-bottom: 1rem;
}
.whimsy-text {
  font-size: 1.5rem;
  font-weight: 500;
}
</style>

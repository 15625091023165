/* eslint-disable no-underscore-dangle */
import createAuth0Client, { Auth0Client, LogoutOptions, User } from '@auth0/auth0-spa-js'
import { Ref, ref, watch } from 'vue'
import { useErrorService, isAppError } from './ErrorService'
import { appLog } from './Logging'

type RedirectCallback = () => Promise<void>

export interface AuthCenter {
  loginWithRedirect(returnTo?: string): void
  loginWithPopup(): Promise<void>
  showSignupPopup(returnTo?: string): Promise<void>
  logout(options?: LogoutOptions): Promise<void>
  getIdToken(): Promise<string>
  getAlliedRoles(): Promise<string[]>
  isSiteAdmin(): boolean
  get loggedIn(): Ref<boolean>
  get authToken(): Promise<string>
  get lastIdToken(): string | undefined
  get pictureUrl(): string | undefined
  setRedirectCallback(callback: RedirectCallback): void
  handleRedirectCallback(): Promise<void>
}

type AuthedUser = User

class AuthCenterImpl implements AuthCenter {
  private loading = true
  private isAuthed = ref(false)
  private isAdmin = false
  private user?: AuthedUser
  private auth0Client: Auth0Client
  private domain: string
  private clientId: string
  private redirectCallback?: RedirectCallback
  private _lastIdToken: string | undefined

  constructor(authClient: Auth0Client, domain: string, clientId: string) {
    this.auth0Client = authClient
    this.domain = domain
    this.clientId = clientId
    watch([() => this.auth0Client.isAuthenticated()], async (newValue) => {
      console.log('auth0 authed changed')
      this.isAuthed.value = await newValue[0]
    })
  }

  setRedirectCallback(callback: RedirectCallback) {
    this.redirectCallback = callback
  }

  get loggedIn() {
    return this.isAuthed
  }

  isSiteAdmin(): boolean {
    return this.isAdmin
  }

  get pictureUrl(): string | undefined {
    return this.user?.picture
  }

  async getAlliedRoles(): Promise<string[]> {
    const user = await this.auth0Client.getUser()
    if (user !== undefined) {
      return (user['https://nowallied.com/roles'] as string[]) || []
    }
    return []
  }

  get authToken(): Promise<string> {
    return this.auth0Client.getTokenSilently()
  }

  get lastIdToken() {
    return this._lastIdToken
  }

  async getIdToken(): Promise<string> {
    if (this._lastIdToken) return this._lastIdToken
    const rsp = await this.auth0Client.getTokenSilently({
      detailedResponse: true,
    })
    this._lastIdToken = rsp.id_token
    return rsp.id_token
  }

  // constructor can't be async, so this must be called after creation
  async initialize() {
    try {
      this.loading = true
      try {
        await this.auth0Client.getTokenSilently()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        // only log error if not a login_required, which can happen if no cookie
        if (Object.prototype.hasOwnProperty.call(e, 'error')) {
          const err = e['error'] as string
          if (err && err !== 'login_required') {
            console.log(`error silently logging in: ${e}`)
          }
        }
      }
      this.isAuthed.value = await this.auth0Client.isAuthenticated()
      if (this.isAuthed.value) {
        await this.handleLoginSuccess()
      }
    } finally {
      this.loading = false
    }
  }

  async handleLoginSuccess() {
    try {
      this.user = await this.auth0Client.getUser()
      const roles = await this.getAlliedRoles()
      this.isAdmin = roles.indexOf('SiteAdmin') > -1
      await this.getIdToken()
      if (this.isAuthed.value && this.redirectCallback) {
        await this.redirectCallback()
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      appLog.warn(`error handling login successful: ${e}`)
    }
  }

  async handleRedirectCallback() {
    this.loading = true
    try {
      await this.auth0Client.handleRedirectCallback()
      this.user = await this.auth0Client.getUser()
      this.isAuthed.value = true
      if (this.user?.email !== undefined) {
        void this.handleLoginSuccess()
      }
      if (this.redirectCallback) await this.redirectCallback()
    } catch (e) {
      if (isAppError(e)) {
        useErrorService().onError(e)
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`unknown error ${e}`)
      }
    } finally {
      this.loading = false
    }
  }

  loginWithRedirect(returnTo: string = window.location.origin) {
    return this.auth0Client.loginWithRedirect({ redirect_uri: returnTo })
  }

  async showSignupPopup(returnTo = '/'): Promise<void> {
    try {
      await this.auth0Client.loginWithPopup({
        screen_hint: 'signup',
        redirect_uri: returnTo,
      })
    } catch (e) {
      if (isAppError(e)) {
        useErrorService().onError(e)
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`unknown error ${e}`)
      }
    }
  }

  async loginWithPopup(): Promise<void> {
    try {
      await this.auth0Client.loginWithPopup()
    } catch (e) {
      if (isAppError(e)) {
        useErrorService().onError(e)
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`unknown error ${e}`)
      }
    }
  }

  async logout(options: LogoutOptions = { localOnly: false }): Promise<void> {
    const rval = this.auth0Client.logout(options)
    if (rval) {
      return rval
    }
    const worked = await this.auth0Client.isAuthenticated()
    appLog.debug(`faking logout, loggedIn = ${worked ? 'true' : 'false'}`)
    this._lastIdToken = undefined
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise<void>(() => {})
  }
}

let instance: AuthCenterImpl

export const getInstance = (): AuthCenter => instance

// consider changing: sessionCheckExpiryDays (default is 1)
export const useAuthCenter = async (
  callback: RedirectCallback | undefined,
  {
    domain = 'lilybart.us.auth0.com',
    clientId = 'N2080ia8KFurjcMm0cH6QOg8ougXkHFX',
    redirectUri = window.location.origin,
  }
): Promise<AuthCenter> => {
  if (instance) return instance
  const ac = await createAuth0Client({
    redirectUri,
    domain,
    client_id: clientId,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  })
  instance = new AuthCenterImpl(ac, domain, clientId)
  if (callback) instance.setRedirectCallback(callback)
  await instance.initialize()
  return instance
}

<template>
  <site-page padding class="browse-page">
    <div class="row">
      <div class="col-md-2 col-xs-shrink">
        <div class="full-width text-h5">Categories</div>
        <div class="q-pa-sm browse">
          <q-skeleton type="rect" v-if="loadingCategories" style="height: 100px" />
          <q-list class="prev-collection-name">
            <div v-if="parent">
              <router-link :to="linkToTop()"><q-icon :name="biCaretLeftFill" /> Top Level</router-link>
            </div>
            <div v-for="(ancestor, index) in parentHistory" :key="ancestor.collectionId">
              <div v-if="ancestor.collectionId !== parent?.collectionId" :style="styleForParent(index)"><router-link :to="linkToParent(index)"> <q-icon :name="biCaretLeftFill" /> {{ ancestor.name }}</router-link></div>
              <div v-else>
                <div v-if="ancestor.childIds.length > 0" :style="styleForParent(index)"> <q-icon :name="biCaretLeftFill" v-if="parent.collectionId !== ancestor.collectionId" /> {{ ancestor.name }}</div>
              </div>
            </div>
          </q-list>
          <q-list class="rounded-borders">
            <div v-for="aCollection in collections" :key="aCollection.collectionId" class="collection-name" :style="styleForEntry()">
            <router-link :to="linkTo(aCollection)">&nbsp;{{ aCollection.name }}</router-link>
            </div>
          </q-list>
        </div>
      </div>
      <div class="col col-xs-10 col-md-10">
        <div class="text-center text-h5">{{ parent ? parent.name : theFormat?.displayName || '' }}</div>
        <div class="text-error" v-if="errorMessage">{{ errorMessage }}</div>
        <product-list v-else :collection="parent" :format="theFormat" :allowEmpty="true" />
      </div>
    </div>
  </site-page>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import ProductList from 'src/components/ProductsList.vue'
import { onBeforeRouteUpdate, RouteLocationNormalized, useRoute } from 'vue-router'
import { TagCollection, ProductSearchParams, ItemFormat } from '@nowallied/quasar-app-extension-na-core/src/models'
import { biCaretLeftFill } from '@quasar/extras/bootstrap-icons'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { useAppInfoStore } from 'src/stores/appState'
import { useMeta } from 'quasar'
import { stringFromStringOrArray } from '@nowallied/quasar-app-extension-na-core/src/util/routes'
import { useAppServer } from 'src/boot/AppServer'

const route = useRoute()
const infoStore = useSiteInfoStore()
const appState = useAppInfoStore()
const appServer = useAppServer()
const collections = ref<TagCollection[]>([])
const parent = ref<TagCollection|undefined>()
const searchParams = ref(new ProductSearchParams())
const theFormat = ref<ItemFormat|undefined>()
const parentHistory = ref<TagCollection[]>([])
const errorMessage = ref<string|undefined>()
const loadingCategories = ref(true)

const pageTitle = ref('Browse')
useMeta(() => ({ title: pageTitle.value }))

searchParams.value.hasImage = true

// let paths: string[] = []

// console.log(`route params is ${JSON.stringify(paths)}`)

function linkTo(collection: TagCollection) {
  if (theFormat.value) {
    if (parent.value === undefined) return `/format/${theFormat.value.name}/${collection.pathName}`
    let basePath = `/format/${theFormat.value.name}/`
    parentHistory.value.forEach((p) => { basePath = `${basePath}${p.pathName}/` })
    return `${basePath}${collection.pathName}`
  }
  let basePath = '/browse'
  parentHistory.value.forEach((p) => { basePath = `${basePath}/${p.pathName}` })
  return `${basePath}/${collection.pathName}`
}

function linkToParent(index: number) {
  let fmtStr = ''
  if (theFormat.value) {
    fmtStr = `/${theFormat.value.name}`
  }
  // console.log(`linking to parent index:${index}: pathName: ${parentHistory.value[index].pathName}, parent:${parent.value?.pathName}`)
  if (index < 0 || index >= parentHistory.value.length) {
    return '/browse'
  }
  let basePath = `/format${fmtStr}`
  parentHistory.value.slice(0, index + 1).forEach((p) => { basePath = `${basePath}/${p.pathName}` })
  // if (basePath.endsWith('/')) return basePath.slice(0, -1)
  return basePath
}

function linkToTop() {
  if (theFormat.value) return `/format/${theFormat.value.name}`
  return '/browse'
}

function styleForParent(index: number) {
  return `margin-left: ${(index + 1) * 8}px`
}

function styleForEntry() {
  return `margin-left: ${(parentHistory.value.length + 2) * 8}px`
}

// type PathType = typeof route.params.collectionId

async function prepPage(pageRoute: RouteLocationNormalized) {
  loadingCategories.value = true
  errorMessage.value = undefined
  searchParams.value.count = appState.itemsPerPage
  const formatName = stringFromStringOrArray(pageRoute.params.formatName)
  theFormat.value = infoStore.formatWithName(formatName)
  if (theFormat.value) {
    pageTitle.value = theFormat.value.displayName
  }
  if (pageRoute.params.collectionId) {
    // need to parse it
    const components = Array.isArray(pageRoute.params.collectionId) ? pageRoute.params.collectionId : [pageRoute.params.collectionId]
    try {
      const collectionStack = infoStore.collectionStackForPath(components)
      if (collectionStack.length < 1) {
        // failed to find it. TODO: report error message
        errorMessage.value = `bad path getting category for ${pageRoute.params.collectionId}`
        parent.value = undefined
        collections.value = infoStore.tagCollections
        parentHistory.value = []
        return
      }
      parent.value = collectionStack[collectionStack.length - 1]
      collections.value = parent.value.children
      parentHistory.value = collectionStack
    } catch (e) {
      parent.value = undefined
      errorMessage.value = 'Invalid category path'
      collections.value = infoStore.tagCollections
    }
  } else {
    if (theFormat.value) {
      searchParams.value.formatIds = [theFormat.value.formatId]
      collections.value = []
      collections.value = await appServer.collectionsForFormat(theFormat.value.formatId)
    } else {
      collections.value = infoStore.tagCollections
      pageTitle.value = 'Browse'
    }
    parent.value = undefined
    parentHistory.value = []
  }
  loadingCategories.value = false
}

// watch(() => route.params.formatName, ()))

// watch([() => route.params.formatName, () => route.params.collectionId], async () => {
//   await prepPage(route)
// })

onBeforeRouteUpdate(async (to) => {
  await prepPage(to)
})

onBeforeMount(async () => {
  await prepPage(route)
})
</script>

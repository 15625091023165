import {
  ShipMethod,
  ShipMethodI,
  ShipRate,
  SiteOrder,
  SiteOrderI,
} from '@nowallied/quasar-app-extension-na-core/src/models'
import { defineStore } from 'pinia'
import { useAppServer } from 'src/boot/AppServer'
import { convertToAlliedError } from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'
import { SavedCard, SavedCardI } from 'src/models/SavedCard'
import { useCartStore } from './cart'
import { AxiosRequestConfig } from 'axios'
import { CheckoutInfo, CheckoutInfoI } from 'src/models/CheckoutInfo'

export type PaymentEngineInfo = {
  engine: string
  squareApplicationId: string
  savedCards?: SavedCardI[]
}

type PreparePaymentResponse = {
  // orderId: number
  engineData: PaymentEngineInfo
  //  order: SiteOrderI
  checkout: CheckoutInfoI
}

interface ICheckoutState {
  shipMethods: ShipMethod[]
  selectedRate?: ShipRate
  paymentEngineInfo: PaymentEngineInfo
  savedCards?: SavedCard[]
  lastOrder?: SiteOrder
}

export const useCheckoutStore = defineStore({
  id: 'CheckoutStore',
  state: (): ICheckoutState => ({
    shipMethods: [],
    paymentEngineInfo: { engine: '', squareApplicationId: '' },
  }),

  getters: {
    shippingMethods: (state) => {
      if (state.shipMethods.length < 1) throw new Error('shipping methods have not been loaded')
      return state.shipMethods
    },
    haveShippingAddress: () => (useCartStore().cart.checkout?.addressId || 0) > 0,
  },

  actions: {
    async loadShippingMethods() {
      const res = await useAppServer().axios.get('/checkout/shipMethods')
      const rawData = res.data as ShipMethodI[]
      this.shipMethods = rawData.map((m) => new ShipMethod(m))
    },
    shippingMethodWithId(methodId: number): ShipMethod | undefined {
      return this.shipMethods.find((m) => m.shipmethodId === methodId)
    },
    resetCheckout() {
      this.savedCards = undefined
    },
    async cancelOrder() {
      const res = await useAppServer().axios.post('/checkout/cancel')
      useCartStore().cart.updateTo(res.data)
      await useCartStore().clearCart()
    },
    async saveCheckoutInfo(info: CheckoutInfo) {
      const res = await useAppServer().axios.put('/checkout', info)
      info.updateTo(res.data as CheckoutInfoI)
    },
    async setDefaultCard(cardIdentifier: string) {
      const res = await useAppServer().axios.put('/checkout/defaultPayment', {
        payment: cardIdentifier,
      })
      if (res.status > 299) throw new Error('failed to set default card')
    },
    async setShippingAddress(addressId: number) {
      const res = await useAppServer().axios.post(`/checkout/saveShippingAddr/${addressId}`, {})
      if (res.status !== 200) throw new Error('failed to set shipping address')
      useCartStore().cart.updateTo(res.data)
    },
    async getShippingRates() {
      const res = await useAppServer().axios.get('/checkout/rates')
      return (res.data as ShipRate[]).sort((a, b) => Number(a.listRate) - Number(b.listRate))
    },
    async setShippingRate(rate: ShipRate) {
      const data = { uniqueId: rate.uniqueId, shipmethodId: rate.method.shipmethodId }
      const res = await useAppServer().axios.put('/checkout/rate', data, {
        headers: { 'Content-Type': 'application/json' },
      })
      if (res.status !== 200) throw new Error('failed to set shipping method')
      useCartStore().cart.updateTo(res.data)
    },
    async preparePaymentSelection() {
      if (this.paymentEngineInfo?.engine?.length > 4) {
        console.log('skipping preparePaymentSelection')
        return
      }
      const res = await useAppServer().axios.get<PreparePaymentResponse>('/checkout/payInfo')
      this.paymentEngineInfo = res.data.engineData
      this.savedCards = res.data.engineData.savedCards?.map((card) => new SavedCard(card))
      useCartStore().cart.checkout?.updateTo(res.data.checkout)
      console.log(`loaded ${this.savedCards?.length} cards`)
    },
    /** @param {unknown} info - The data to send to the server for the payment */
    async finishPayment(info: unknown) {
      try {
        const res = await useAppServer().axios.post('/checkout/pay', info)
        useCartStore().cart.updateTo(res.data)
      } catch (error) {
        const aerror = convertToAlliedError(error)
        throw aerror
      }
    },
    async placeOrder() {
      try {
        const config: AxiosRequestConfig = {
          method: 'post',
          url: '/checkout/confirm',
          data: {},
        }
        const res = await useAppServer().axios.request<SiteOrderI>(config)
        this.lastOrder = new SiteOrder(res.data)
        await useCartStore().fetchCart()
        return this.lastOrder?.orderIdentifier
      } catch (error) {
        const aerror = convertToAlliedError(error)
        throw aerror
      }
    },
  },
})

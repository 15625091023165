import { defineStore } from 'pinia'
import { useAppServer } from 'src/boot/AppServer'
import { Cart, CartItem, IncomingCart } from 'src/models/Cart'
import { useCheckoutStore } from './checkout'
import { CacheRequestConfig } from 'axios-cache-interceptor'

export type AddCartArgs = {
  itemId: number
  quantity: number
  customString?: string
}

export type CartItemAdded = {
  item: CartItem
  quantity: number
}

interface ICartState {
  cart: Cart
  lastAddedItem: CartItemAdded | null
}

export const useCartStore = defineStore({
  id: 'CartStore',
  state: (): ICartState => ({ cart: new Cart(undefined), lastAddedItem: null }),

  getters: {
    isEmpty: (state) => state.cart.items.length === 0,
  },

  actions: {
    async fetchCart() {
      const config: CacheRequestConfig = {
        method: 'get',
        url: '/cart',
        cache: false,
      }
      const res = await useAppServer().axios.request(config)
      const updatedCart = res.data as IncomingCart
      this.cart.updateTo(updatedCart)
      return this.cart
    },

    async fetchDigitalPaymentInfo() {
      const res = await useAppServer().axios.get('/checkout/digital')
      console.log('got digital payment info')
      return res.data
    },

    async addToCart(data: AddCartArgs) {
      const config: CacheRequestConfig = {
        method: 'post',
        url: '/cart',
        data,
        cache: false,
      }
      const res = await useAppServer().axios.request<IncomingCart>(config)
      this.cart.updateTo(res.data)
      const citem = this.cart.itemWithId(data.itemId)
      if (citem === undefined) throw Error('added item not in cart')
      this.$patch((state) => {
        state.lastAddedItem = { item: citem, quantity: data.quantity }
      })
      return citem
    },

    async bulkAddToCart(data: AddCartArgs[]) {
      const config: CacheRequestConfig = {
        method: 'post',
        url: '/cart/bulk',
        data,
        cache: false,
      }
      const res = await useAppServer().axios.request<IncomingCart>(config)
      this.cart.updateTo(res.data)
      useCheckoutStore().resetCheckout()
      return this.cart
    },

    async clearCart() {
      const config: CacheRequestConfig = {
        method: 'delete',
        url: '/cart',
      }
      const res = await useAppServer().axios.request<IncomingCart>(config)
      this.cart.updateTo(res.data)
      useCheckoutStore().resetCheckout()
      return this.cart
    },

    async updateCartItem(data: AddCartArgs[]) {
      const config: CacheRequestConfig = {
        method: 'put',
        url: '/cart',
        data,
        cache: false,
      }
      const res = await useAppServer().axios.request<IncomingCart>(config)
      this.cart.updateTo(res.data)
      useCheckoutStore().resetCheckout()
      return this.cart
    },

    async removeCartItem(itemId: number) {
      const config: CacheRequestConfig = {
        method: 'delete',
        url: `/cart/${itemId}`,
        cache: false,
      }
      const res = await useAppServer().axios.request<IncomingCart>(config)
      this.cart.updateTo(res.data)
      useCheckoutStore().resetCheckout()
      return this.cart
    },
  },
})

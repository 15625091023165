import { ItemFormat, ProductSearchParams, emptySearchParams } from 'app/na-core/src/models'
import { singleParamString } from 'app/na-core/src/util/routes'
import { LocationQuery } from 'vue-router'

export class UserSearchParams {
  _searchString: string
  page: number
  formatIds: number[]

  constructor() {
    this._searchString = ''
    this.page = 1
    this.formatIds = []
  }

  get hasParams() {
    return this._searchString || this.formatIds.length > 0 || this.page > 1
  }

  get searchString() {
    return this._searchString
  }
  set searchString(value: string) {
    this._searchString = value || ''
    this.page = 1
  }

  addFormat(filter: ItemFormat) {
    if (!this.formatIds.includes(filter.formatId)) {
      this.formatIds.push(filter.formatId)
      this.page = 1
    }
  }

  removeFormat(filter: ItemFormat) {
    const index = this.formatIds.indexOf(filter.formatId)
    if (index !== -1) {
      this.formatIds.splice(index, 1)
      this.page = 1
    }
  }

  copyFrom(other: UserSearchParams) {
    this._searchString = other._searchString
    this.page = other.page
    this.formatIds = other.formatIds
  }

  parseQueryLocation(location: LocationQuery) {
    if (location.page) {
      this.page = parseInt(singleParamString(location.page) ?? '1')
    }
    if (location.q) {
      this._searchString = singleParamString(location.q) ?? ''
    }
    if (location.formatIds) {
      this.formatIds =
        singleParamString(location.formatIds)
          ?.split(',')
          .map((s) => parseInt(s, 10)) ?? []
    } else {
      this.formatIds = []
    }
  }

  toQueryParams(): Record<string, string> {
    return {
      q: this._searchString,
      page: this.page.toString(),
      formatIds: this.formatIds.join(','),
    }
  }

  searchTermsDiffer(other: UserSearchParams): boolean {
    return this._searchString !== other._searchString || this.formatIds !== other.formatIds
  }

  equals(other: UserSearchParams): boolean {
    return (
      this.page === other.page &&
      this._searchString === other._searchString &&
      this.formatIds === other.formatIds
    )
  }

  toSearchParams(rowsPerPage: number): ProductSearchParams {
    const offset = (this.page - 1) * rowsPerPage
    let name = this._searchString
    let itemIds: number[] = []
    // If the search string is all numbers, treat it as a list of item ids
    if (name.match(/^[\d\s]+$/)) {
      itemIds = name
        .replace(/\s+/, ' ')
        .split(' ')
        .map((s) => parseInt(s, 10))
      name = ''
    }
    return new ProductSearchParams({
      ...emptySearchParams,
      name,
      itemIds,
      offset,
      count: rowsPerPage,
      hasImage: true,
      formatIds: this.formatIds,
    })
  }
}

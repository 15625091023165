import Currency from 'currency.js'
import { Entity } from './Entity'
import { date } from 'quasar'
import { z } from 'zod'
import { isEqual } from 'lodash'

export enum PromoType {
  Amount = 'Amount',
  Percent = 'Percent',
}

export const promotionSchema = z.object({
  promotionId: z.number().nonnegative(),
  code: z.string().min(3, 'code must contain 3 characters'),
  type: z.nativeEnum(PromoType),
  amount: z.union([z.instanceof(Currency), z.string()]),
  maxAmount: z.union([z.instanceof(Currency), z.string()]),
  minOrderAmount: z.union([z.instanceof(Currency), z.string()]),
  limitPerUser: z.number().nonnegative(),
  combinable: z.boolean(),
  firstOrderOnly: z.boolean(),
  wholesale: z.boolean(),
  enabled: z.boolean(),
  validFrom: z.union([z.date(), z.string()]).optional(),
  validUntil: z.union([z.date(), z.string()]).optional(),
  lastModified: z.union([z.date(), z.string()]).optional(),
  details: z.string(),
})

export type PromotionI = z.infer<typeof promotionSchema>

function toDate(val: Date | string): Date {
  if (typeof val === 'string') return new Date(val)
  return val
}

function toOptionalDate(val: Date | string | undefined): Date | undefined {
  if (typeof val === 'string') return new Date(val)
  if (val instanceof Date) return val
  return undefined
}

/** This wrapper class lets us add private properties that won't be included in JSON */
class PromoPrivate {
  validFrom?: Date
  validUntil?: Date

  toJSON() {
    return undefined
  }
}

export class Promotion implements PromotionI, Entity {
  readonly entityType = 'Promotion'

  promotionId = 0
  code!: string
  type!: PromoType
  amount!: Currency
  maxAmount!: Currency
  minOrderAmount!: Currency
  limitPerUser!: number
  combinable!: boolean
  wholesale!: boolean
  firstOrderOnly!: boolean
  enabled!: boolean
  private _promopriv = new PromoPrivate()
  lastModified!: Date
  details = ''

  constructor(data: PromotionI) {
    this.assign(data)
  }

  private assign(data: PromotionI) {
    this.promotionId = data.promotionId
    this.code = data.code
    this.type = data.type
    this.limitPerUser = data.limitPerUser
    this.combinable = data.combinable
    this.wholesale = data.wholesale
    this.enabled = data.enabled
    this.firstOrderOnly = data.firstOrderOnly
    this.amount = new Currency(data.amount)
    this.minOrderAmount = new Currency(data.minOrderAmount)
    this.maxAmount = new Currency(data.maxAmount)
    this.lastModified = toDate(data.lastModified || new Date())
    this._promopriv.validFrom = toOptionalDate(data.validFrom)
    this._promopriv.validUntil = toOptionalDate(data.validUntil)
    this.details = data.details
  }

  update(data: PromotionI) {
    this.assign(data)
  }

  get amountString() {
    if (this.type === PromoType.Amount) return '$' + this.amount.toString()
    return `${this.amount.intValue / 100}%`
  }

  get active() {
    const d = new Date()
    if (this._promopriv.validFrom === undefined) {
      if (this._promopriv.validUntil === undefined) return true
      return d <= this._promopriv.validUntil
    }
    if (this._promopriv.validUntil === undefined)
      return d >= this._promopriv.validFrom
    return date.isBetweenDates(
      d,
      this._promopriv.validFrom,
      this._promopriv.validUntil
    )
  }

  private formatForServer(date: Date | undefined) {
    if (date === undefined) return undefined
    return date.toISOString().split('.')[0] + 'Z'
  }

  get validFrom() {
    return this._promopriv.validFrom
  }
  set validFrom(value: Date | undefined) {
    this._promopriv.validFrom = value
  }

  get validFromStr(): string | undefined {
    // Chrome datetime picker returns a value with Z at the end, but refuses to take one as a value
    // TODO: test if this is a problem with other browsers. if so, use navigator.userAgent.includes("Chrome")
    return this._promopriv.validFrom
      ? this._promopriv.validFrom.toISOString().replace(/Z$/, '')
      : undefined
  }
  set validFromStr(value: string | Date | undefined) {
    if (!value) this._promopriv.validFrom = undefined
    else if (typeof value === 'string')
      this._promopriv.validFrom = new Date(value)
    else if (value instanceof Date) this._promopriv.validFrom = value
  }

  get validUntil() {
    return this._promopriv.validUntil
  }
  set validUntil(value: Date | undefined) {
    this._promopriv.validUntil = value
  }

  get validUntilStr(): string | undefined {
    return this._promopriv.validUntil
      ? this._promopriv.validUntil.toISOString().replace(/Z$/, '')
      : undefined
  }
  set validUntilStr(value: string | Date | undefined) {
    if (!value) this._promopriv.validUntil = undefined
    else if (typeof value === 'string')
      this._promopriv.validUntil = new Date(value)
    else if (value instanceof Date) this._promopriv.validUntil = value
  }

  /// returns false if both dates are defined and until is before from
  get dateRangeIsValid() {
    if (
      this._promopriv.validFrom === undefined ||
      this._promopriv.validUntil === undefined
    )
      return true
    return (
      this._promopriv.validUntil.getTime() > this._promopriv.validFrom.getTime()
    )
  }

  get isPercentOff() {
    return this.type === PromoType.Percent
  }

  isEqualTo(other: Promotion): boolean {
    return (
      this.promotionId == other.promotionId &&
      this.code == other.code &&
      this.limitPerUser == other.limitPerUser &&
      this.combinable == other.combinable &&
      this.wholesale == other.wholesale &&
      this.firstOrderOnly == other.firstOrderOnly &&
      this.type == other.type &&
      isEqual(this.amount, other.amount) &&
      isEqual(this.maxAmount, other.maxAmount) &&
      isEqual(this.minOrderAmount, other.minOrderAmount) &&
      isEqual(this.validFrom, other.validFrom) &&
      isEqual(this.validUntil, other.validUntil) &&
      this.details == other.details
    )
  }

  get idenity(): number {
    return this.promotionId
  }
}

import { boot } from 'quasar/wrappers'
// import { appLog } from '../services/Logging'
import { isAppError, useErrorService } from '../services/ErrorService'

let loaded = false
let inHandler = false

// eslint-disable-next-line @typescript-eslint/require-await
export default boot(async ({ app }) => {
  if (loaded) return
  loaded = true
  const errorService = useErrorService()
  app.config.errorHandler = (err) => {
    try {
      if (inHandler) return
      inHandler = true
      if (err !== undefined && isAppError(err)) {
        errorService.onError(err)
      } else {
        // make sure something is printed even if template expressions causes error
        console.log(err)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        errorService.onError(new Error(`unknown error: ${err}`))
      }
      inHandler = false
    } catch (e: unknown) {
      if (e instanceof Error) console.log('unknown errior', e)
    }
  }
  app.config.warnHandler = (err) => {
    try {
      if (inHandler) return
      inHandler = true
      debugger
      if (isAppError(err)) {
        errorService.onWarn(err)
      } else {
        // make sure something is printed even if template expressions causes error
        console.log(err)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        errorService.onError(new Error(`unknown warning: ${err}`))
      }
      inHandler = false
    } catch (e: unknown) {
      if (e instanceof Error) console.log('unknown errior', e)
    }
  }
})

import { Entity } from './Entity'
import { SiteOrderItemI, SiteOrderItem } from './SiteOrderItem'

export enum OrderStatus {
  Charged = 'Charged',
  Processing = 'Processing',
  Preparing = 'Preparing',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Canceled = 'Canceled',
}

export enum PaymentEngine {
  payByCard = 'payByCard',
  payByNetCard = 'payByNetCard',
  payByNetInvoice = 'payByNetInvoice',
}

export enum LabelSource {
  None = 'None',
  Manual = 'Manual',
  EasyPost = 'EasyPost',
}

export function colorForOrderStatus(status: OrderStatus) {
  switch (status) {
    case OrderStatus.Charged:
    default:
      return 'brown-8'
    case OrderStatus.Processing:
      return 'blue-8'
    case OrderStatus.Preparing:
      return 'green-9'
    case OrderStatus.Shipped:
      return 'deep-orange-14'
    case OrderStatus.Delivered:
      return 'deep-purple-9'
    case OrderStatus.Canceled:
      return 'black'
  }
}

export function displayNameForPaymentEngine(pe: PaymentEngine): string {
  switch (pe) {
    case PaymentEngine.payByCard:
      return 'Credit Card'
    case PaymentEngine.payByNetCard:
      return 'Net Card'
    case PaymentEngine.payByNetInvoice:
      return 'Net Invoice'
  }
}

export interface SiteOrderI {
  orderId: number
  orderIdentifier: string
  status: string
  orderDate: string
  subtotal: string
  taxAmount: string
  shipAmount: string
  shipCost: string
  amountCharged: string
  shipDetails: string
  shipTracking?: string
  addressDescription: string
  paymentDetails: string
  notes: string
  lastModified: string
  labelUrl?: string
  items?: SiteOrderItemI[]
  paid: boolean
  paidOn?: string
  poNumber?: string
  paymentEngine: string
  boxId?: number
  boxName: string
  shipInfo?: string
  labelSource: string
}

export class SiteOrder implements Entity {
  readonly entityType = 'SiteOrder'

  /** primary key, should never be displayed to user */
  orderId: number
  /** identifier displayed to user */
  orderIdentifier: string
  status: OrderStatus
  orderDate: Date
  subtotal: string
  taxAmount: string
  shipAmount: string
  shipCost: string
  amountCharged: string
  shipDetails: string
  shipTracking?: string
  addressDescription: string
  paymentDetails: string
  notes: string
  lastModified: Date
  labelUrl?: string
  items: SiteOrderItem[] = []
  paid: boolean
  paidOn?: Date
  poNumber?: string
  paymentEngine: PaymentEngine
  boxId?: number
  boxName = ''
  shipInfo?: string
  labelSource: LabelSource

  constructor(data: SiteOrderI) {
    this.orderId = data.orderId
    try {
      this.status = OrderStatus[data.status as keyof typeof OrderStatus]
    } catch (e) {
      console.error(`unkown order status received: ${data.status}`)
      this.status = OrderStatus.Canceled
    }
    this.orderIdentifier = data.orderIdentifier
    this.subtotal = data.subtotal
    this.taxAmount = data.taxAmount
    this.shipAmount = data.shipAmount
    this.shipCost = data.shipCost
    this.amountCharged = data.amountCharged
    // the string does not include a time, so the date will be set to midnight, timezone will show it
    // to be the previous day. So we set the hours to 12 to get a correct US date. probably should shift based on
    // timezone, but this is a US only app.
    this.orderDate = new Date(data.orderDate)
    this.orderDate.setUTCHours(12, 0, 0, 0)
    this.shipDetails = data.shipDetails
    this.addressDescription = data.addressDescription
    this.paymentDetails = data.paymentDetails
    this.notes = data.notes
    this.labelUrl = data.labelUrl
    this.lastModified = new Date(data.lastModified)
    this.paid = data.paid
    this.paidOn = data.paidOn ? new Date(data.paidOn) : undefined
    this.poNumber = data.poNumber
    this.boxId = data.boxId
    this.boxName = data.boxName
    this.shipInfo = data.shipInfo
    this.shipTracking = data.shipTracking
    if (data.items) {
      this.items = data.items.map((oi) => new SiteOrderItem(oi))
    }
    try {
      this.paymentEngine = PaymentEngine[data.paymentEngine as keyof typeof PaymentEngine]
    } catch (ignored) {
      this.paymentEngine = PaymentEngine.payByCard
    }
    try {
      this.labelSource = LabelSource[data.labelSource as keyof typeof LabelSource]
    } catch (ignored) {
      this.labelSource = LabelSource.None
    }
  }

  updateTo(updated: SiteOrder) {
    this.status = updated.status
    this.shipDetails = updated.shipDetails
    this.shipTracking = updated.shipTracking
    this.notes = updated.notes
    this.lastModified = updated.lastModified
    this.labelUrl = updated.labelUrl
    this.paid = updated.paid
    this.paidOn = updated.paidOn
    this.poNumber = updated.poNumber
    this.paymentEngine = updated.paymentEngine
    this.shipInfo = updated.shipInfo
    this.labelSource = updated.labelSource
    this.shipTracking = updated.shipTracking
  }

  get idenity(): number {
    return this.orderId
  }

  toJSON(): string {
    return JSON.stringify(this)
  }
}

<template>
    <div class="q-ma-xs col-11">
      <div class="row q-my-lg" v-if="$props.showTitle">
        <div class="col text-h5 text-center">
          {{ group.displayName }}
        </div>
      </div>
      <div class="q-ma-sm q-px-lg">
        <q-virtual-scroll :items="group.items" virtual-scroll-horizontal>
          <template v-slot="{item}">
            <div class="product-box items-center q-ma-sm">
              <router-link :to="{ name: 'ProductDetails', params: { itemId: item.itemId }}">
                <div class="q-mb-sm q-mx-md text-center" style="height:290">
                  <q-img
                    v-if="!item.hasImage"
                    src="/images/product/noImage.png"
                  />
                  <product-image v-else :product="item" :format="formatForId(item.formatId)" :type="imageType" />
                </div>
              </router-link>
              <div class="add-to-cart text-center wrap">
                <router-link :to="{ name: 'ProductDetails', params: { itemId: item.itemId }}">
                  {{ item.name }}
                </router-link>
              </div>
              <div class="add-to-cart text-center wrap q-mx-sm">
                <q-btn
                  size="md"
                  flat
                  v-if="props.showAddToCart"
                  @click="addToCart(item)"
                  label="Add to Cart"
                />
              </div>
            </div>
          </template>
        </q-virtual-scroll>
      </div>
    </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { ItemFormat, ItemGroup, Product } from '@nowallied/quasar-app-extension-na-core/src/models'
import { useSiteInfoStore } from 'src/stores/siteInfo'
import { ImageType } from 'src/utils/ImageUtils'
import ProductImage from 'src/components/ProductImage.vue'
import { useCartStore } from 'src/stores/cart'
import { useQuasar } from 'quasar'
import { toAlliedError, useErrorService } from '@nowallied/quasar-app-extension-na-core/src/services/ErrorService'

const props = defineProps({
  group: { type: Object as PropType<ItemGroup>, required: true },
  showTitle: { type: Boolean, default: true },
  thumbnails: { type: Boolean, default: false },
  showAddToCart: { type: Boolean, default: false },
})

const $q = useQuasar()
const siteInfo = useSiteInfoStore()
// const groups = computed(() => siteInfo.itemGroups)

const imageType = computed(() => props.thumbnails ? ImageType.Thumb : ImageType.Listing)

function formatForId(formatId: number): ItemFormat {
  return siteInfo.formatWithIdOrDefault(formatId)
}

const addToCart = async (product: Product) => {
  const cartStore = useCartStore()
  if (product.itemId === undefined) throw new Error('invalid product') // should never happen
  $q.loading.show({ delay: 1000 })
  try {
    await cartStore.addToCart({ itemId: product.itemId, quantity: 1 })
  } catch (error) {
    useErrorService().onError(toAlliedError(error))
  } finally {
    $q.loading.hide()
  }
}

</script>

<style lang="scss">
.featured-carousel {
  width: 100%;
}
.featured-content {
  height: 160px;
  width: 200px;
}
</style>

import { LocationQueryValue } from 'vue-router'

/** converts an entry from $route.query into an array of strings */
export function paramToString(
  value: LocationQueryValue | LocationQueryValue[]
): string[] {
  // the as ignores null values, as they should never appear in an array
  return Array.isArray(value)
    ? (value.filter((n) => n) as string[])
    : value
    ? [value]
    : []
}

/** returns the first (or only) string value from a $route.query entry */
export function singleParamString(
  value: LocationQueryValue | LocationQueryValue[]
): string | undefined {
  const vals = paramToString(value)
  if (vals.length > 0) return vals[0]
  return undefined
}

export function stringFromStringOrArray(val: string | string[]) {
  if (Array.isArray(val)) return val[0]
  return val
}

import {
  PricePoint,
  PricePointI,
  Product,
  ProductI,
} from '@nowallied/quasar-app-extension-na-core/src/models'

import { CheckoutInfo, CheckoutInfoI } from './CheckoutInfo'

export interface IncomingCartItem {
  quantity: number
  pricePoint: PricePointI
  linePrice: string
  customString: string
  itemId: number
  item: ProductI
  greetingCard: boolean
  imageCdnPath: string
  inStock: boolean
  totalWeight: number
}

export class CartItem {
  itemId: number
  quantity: number
  pricePoint: PricePoint
  linePrice: string
  customString: string
  item: Product
  greetingCard: boolean
  imageCdnPath: string
  inStock: boolean
  totalWeight: number

  constructor(data: IncomingCartItem) {
    this.quantity = data.quantity
    this.pricePoint = new PricePoint(data.pricePoint)
    this.linePrice = data.linePrice
    this.customString = data.customString
    this.itemId = data.itemId
    this.item = new Product(data.item)
    this.greetingCard = data.greetingCard
    this.imageCdnPath = data.imageCdnPath
    this.inStock = data.inStock
    this.totalWeight = data.totalWeight
  }

  get thumbTag() {
    return this.item.thumbTag
  }
  get imageTag() {
    return this.item.imageTag
  }
}

export interface IncomingCart {
  cartVersion: number
  subTotal: string
  cartId: string
  shippingAddressId?: number
  items: IncomingCartItem[]
  checkout?: CheckoutInfoI
}

export class Cart {
  cartVersion!: number
  subTotal!: string
  cartId!: string
  items!: CartItem[]
  checkout?: CheckoutInfo

  constructor(data: IncomingCart | undefined) {
    if (data) {
      this.assign(data)
    } else {
      this.cartVersion = 0
      this.subTotal = ''
      this.cartId = ''
      this.items = []
    }
  }

  get isEmpty() {
    return this.items.length === 0
  }

  itemWithId(itemId: number) {
    return this.items.find((ci) => ci.itemId === itemId)
  }

  updateTo(data: IncomingCart) {
    this.assign(data)
  }

  private assign(data: IncomingCart) {
    this.cartId = data.cartId
    this.cartVersion = data.cartVersion
    this.subTotal = data.subTotal
    this.items.splice(0, this.items.length)
    this.items = data.items.map((i) => new CartItem(i))
    this.checkout = new CheckoutInfo(data.checkout)
  }
}
